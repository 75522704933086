import * as React from "react";
import {
  Modal,
  Box,
  Typography,
  TextField,
  Checkbox,
  FormControlLabel,
  Button,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import Autocomplete from "@mui/material/Autocomplete";
import { FormControl, InputLabel } from "@mui/material";
import act from "../../../utils/autoCompleteTheme";

export default function EditUserModal({
  user,
  openState,
  handleCancel,
  handleSave,
  titles,
  departments,
  users,
}) {
  const [userTmp, setUserTmp] = React.useState(user);

  React.useEffect(() => {
    setUserTmp(user);
  }, [user, setUserTmp]);

  const handleTmp = (user) => {
    setUserTmp({ ...user });
  };

  if (!user || !userTmp) return null;

  return (
    <Modal
      open={openState}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          border: "0.1px solid #FFFFFF4B",
          borderRadius: 1,
          boxShadow: 24,
          width: { xs: "90%", md: 700 },
          maxHeight: { xs: "90%", md: "80%" },
          p: {
            xs: 2,
            md: 3,
          },
          overflowY: "scroll",
        }}
      >
        <Grid container spacing={2} columns={12}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            size={{ xs: 12 }}
          >
            Editando {userTmp.firstNames} {userTmp.fatherLastName}{" "}
            {userTmp.motherLastName} | {userTmp.rut}-{userTmp.dv}
          </Typography>

          <Grid size={{ xs: 12, lg: 12 }}></Grid>
          <Grid size={{ xs: 8, lg: 8 }}>
            <FormControl sx={{ m: 0, width: "100%" }}>
              <InputLabel id="rut-label" sx={{ transform: 'translate(4px, -23px)' }}>RUT</InputLabel>
            <TextField
              id="rut"
              fullWidth
                labelId="rut-label"
                variant="outlined"
              disabled
              value={userTmp.rut}
            />
            </FormControl>
          </Grid>
          <Grid size={{ xs: 2, lg: 2 }}>
            <FormControl sx={{ m: 0, width: "100%" }}>
              <InputLabel id="dv-label" sx={{ transform: 'translate(4px, -23px)' }}>DV</InputLabel>
            <TextField
              id="dv"
                labelId="dv-label"
                variant="outlined"
              value={userTmp.dv}
            />
            </FormControl>
          </Grid>
          <Grid size={{ xs: 2, lg: 2 }}>
            <FormControlLabel
              label="Activo"
              control={
                <Checkbox
                  checked={userTmp.isActive}
                  onChange={(e) => {
                    userTmp.isActive = !userTmp.isActive;
                    handleTmp({ ...userTmp });
                  }}
                />
              }
            />
          </Grid>

          <Grid size={{ xs: 12, lg: 12 }}></Grid>
          <Grid size={{ xs: 12, lg: 12 }}>
            <FormControl sx={{ m: 0, width: "100%" }}>
              <InputLabel id="names-label" sx={{ transform: 'translate(4px, -23px)' }}>Nombres</InputLabel>
            <TextField
              id="nombres"
              fullWidth
                labelId="names-label"
                variant="outlined"
              value={userTmp.firstNames}
              onChange={(e) => {
                handleTmp((prev) => ({
                  ...prev,
                  firstNames: e.target.value,
                }));
              }}
            />
            </FormControl>
          </Grid>

          <Grid size={{ xs: 12, lg: 12 }}></Grid>
          <Grid size={{ xs: 6, lg: 6 }}>
            <FormControl sx={{ m: 0, width: "100%" }}>
              <InputLabel id="paterno-label" sx={{ transform: 'translate(4px, -23px)' }}>Apellido Paterno</InputLabel>
            <TextField
              id="apaterno"
              fullWidth
                labelId="paterno-label"
                variant="outlined"
              value={userTmp.fatherLastName}
              onChange={(e) => {
                userTmp.fatherLastName = e.target.value;
                handleTmp({ ...userTmp });
              }}
            />
            </FormControl>
          </Grid>
          <Grid size={{ xs: 6, lg: 6 }}>
            <FormControl sx={{ m: 0, width: "100%" }}>
              <InputLabel id="materno-label" sx={{ transform: 'translate(4px, -23px)' }}>Apellido Materno</InputLabel>
            <TextField
              id="amateno"
              fullWidth
                labelId="materno-label"
                variant="outlined"
              value={userTmp.motherLastName}
              onChange={(e) => {
                userTmp.motherLastName = e.target.value;
                handleTmp({ ...userTmp });
              }}
            />
            </FormControl>
          </Grid>

          <Grid size={{ xs: 12, lg: 12 }}></Grid>
          <Grid size={{ xs: 6, lg: 6 }}>
            <FormControl sx={{ m: 0, width: "100%" }}>
              <InputLabel id="mail-label" sx={{ transform: 'translate(4px, -23px)' }}>Mail</InputLabel>
            <TextField
              id="email"
              fullWidth
                labelId="mail-label"
                variant="outlined"
              value={userTmp.email}
              onChange={(e) => {
                userTmp.email = e.target.value;
                handleTmp({ ...userTmp });
              }}
            />
            </FormControl>
          </Grid>
          <Grid size={{ xs: 6, lg: 6 }}>
            <FormControl sx={{ m: 0, width: "100%" }}>
              <InputLabel id="dpt-label" sx={{ transform: 'translate(4px, -23px)' }}>Departamento</InputLabel>
            <Autocomplete
              size="large"
              disablePortal
              options={departments.map((option) => option.name)}
                sx={act}
              value={userTmp.department?.name ?? ""}
              onChange={(e, value) => {
                userTmp.department = departments.find(
                  (dep) => dep?.name === value
                );
                handleTmp({ ...userTmp });
              }}
              renderInput={(params) => (
                <TextField {...params} labelId="dpt-label" />
              )}
            />
            </FormControl>
          </Grid>

          <Grid size={{ xs: 12, lg: 12 }}></Grid>
          <Grid size={{ xs: 6, lg: 6 }}>
            <FormControl sx={{ m: 0, width: "100%" }}>
              <InputLabel id="role-label" sx={{ transform: 'translate(4px, -23px)' }}>Cargo</InputLabel>
            <Autocomplete
              size="large"
              disablePortal
              options={titles.map((option) => option.name)}
                sx={act}
              value={userTmp.title?.name ?? ""}
              onChange={(e, value) => {
                userTmp.title = titles.find((dep) => dep?.name === value);
                handleTmp({ ...userTmp });
              }}
                renderInput={(params) => <TextField {...params} labelId="role-label" />}
            />
            </FormControl>
          </Grid>
          {/* LINE 5 */}
          <Grid size={{ xs: 6, lg: 6 }}>
            <FormControl sx={{ m: 0, width: "100%" }}>
              <InputLabel id="manager-label" sx={{ transform: 'translate(4px, -23px)' }}>Supervisor</InputLabel>
            <Autocomplete
              size="large"
              disablePortal
              value={
                userTmp.manager
                  ? `${userTmp.manager?.firstNames?.split(" ")[0]} ${
                      userTmp.manager?.fatherLastName
                    } | ${userTmp.manager.rut}-${userTmp.manager.dv}`
                  : ""
              }
              options={users.map(
                (option) =>
                  `${option.firstNames.split(" ")[0]} ${
                    option.fatherLastName
                  } | ${option.rut}-${option.dv}`
              )}
              onChange={(e, value) => {
                if (!value) {
                  userTmp.manager = null;
                  return;
                }
                let rut = value.split(" | ")[1].split("-")[0];
                userTmp.manager = users.find(
                  (userTmp) => userTmp.rut.toString() === rut.toString()
                );
                handleTmp({ ...userTmp });
                }} 
                sx={act}
              renderInput={(params) => (
                <TextField {...params} labelId="manager-label" />
              )}
            />
            </FormControl>
          </Grid>

          <Grid container justifyContent="flex-end" size={{ xs: 12, lg: 12 }}>
            <Button
              variant="text"
              onClick={() => {
                handleCancel();
              }}
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                handleSave(userTmp, "put");
              }}
            >
              Guardar
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
}
