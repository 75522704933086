import * as React from "react";
import { Modal, Box, Typography, TextField, Button } from "@mui/material";
import Grid from "@mui/material/Grid2";
import Autocomplete from "@mui/material/Autocomplete";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import act from "../../../utils/autoCompleteTheme";

export default function CreateTitleModal({
  openState,
  handleCancel,
  handleSave,
  departments,
  titles,
  users,
}) {
  const [title, setTitle] = React.useState({
    name: "",
    description: "",
    department: null,
    validator: null,
    authority: 2,
  });

  const optsAuthority = ["Alto", "Medio", "Bajo"];

  if (!openState) {
    return null;
  }

  return (
    <Modal
      open={openState}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          border: "0.1px solid #FFFFFF4B",
          borderRadius: 1,
          boxShadow: 24,
          width: { xs: "90%", md: 700 },
          maxHeight: { xs: "90%", md: "80%" },
          p: {
            xs: 2,
            md: 3,
          },
          overflowY: "scroll",
        }}
      >
        <Grid container spacing={2} columns={12}>
          <Grid size={{ xs: 12, lg: 12 }}>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              size={{ xs: 12 }}
            >
              Creando nuevo Cargo
            </Typography>
          </Grid>

          <Grid size={{ xs: 12, lg: 12 }}></Grid>
          <Grid size={{ xs: 12, lg: 12 }}>
            <FormControl sx={{ m: 0, width: "100%" }}>
              <InputLabel id="name-label" sx={{ transform: 'translate(4px, -23px)' }}>Nombre</InputLabel>
            <TextField
              id="Nombre"
              fullWidth
                labelId="nombre-label"
                variant="outlined"
              value={title.name}
              onChange={(e) => {
                title.name = e.target.value;
                setTitle({ ...title });
              }}
            />
            </FormControl>
          </Grid>

          <Grid size={{ xs: 12, lg: 12 }}></Grid>
          <Grid size={{ xs: 12, lg: 12 }}>
            <FormControl sx={{ m: 0, width: "100%" }}>
              <InputLabel id="descripcion-label" sx={{ transform: 'translate(4px, -23px)' }}>Descripcion</InputLabel>
            <TextField
              id="description"
              fullWidth
                labelId="descripcion-label"
                variant="outlined"
              value={title.description}
              multiline
                sx={{
                  "& .css-a2jdju-MuiInputBase-root-MuiOutlinedInput-root": {
                    height: "inherit",
                  }
                }}
              minRows={1}
              maxRows={5}
              onChange={(e) => {
                title.description = e.target.value;
                setTitle({ ...title });
              }}
            />
            </FormControl>
          </Grid>

          <Grid size={{ xs: 12, lg: 12 }}></Grid>
          <Grid size={{ xs: 12, lg: 4 }}>
            <FormControl sx={{ m: 0, width: "100%" }}>
              <InputLabel id="departamento-label" sx={{ transform: 'translate(4px, -23px)' }}>Departamento</InputLabel>
            <Autocomplete
              size="large"
              disablePortal
                sx={act}
              options={departments.map((option) => option.name)}
              value={
                departments.find((d) => d.id === title.department)?.name ?? null
              }
              onChange={(e, value) => {
                title.department =
                  departments.find((d) => d.name === value)?.id ?? null;
                setTitle({ ...title });
              }}
              renderInput={(params) => (
                <TextField {...params} labelId="departamento-label" />
              )}
            />
            </FormControl>
          </Grid>
          <Grid size={{ xs: 12, lg: 12 }} display={{ xs: "block", lg: "none" }}></Grid>
          <Grid size={{ xs: 12, lg: 4 }}>
            <FormControl sx={{ m: 0, width: "100%" }}>
              <InputLabel id="validador-label" sx={{ transform: 'translate(4px, -23px)' }}>Validador</InputLabel>
            <Autocomplete
              size="large"
                disablePortal 
                sx={act}
              options={users.map(
                (option) =>
                  option.firstNames +
                  " " +
                  option.fatherLastName +
                  " | " +
                  option.rut +
                  "-" +
                  option.dv
              )}
              value={(() => {
                let user = users.find((u) => u.rut === title.validator);
                if (!user) return null;
                return (
                  user.firstNames +
                  " " +
                  user.fatherLastName +
                  " | " +
                  user.rut +
                  "-" +
                  user.dv
                );
              })()}
              onChange={(e, value) => {
                title.validator =
                  users.find(
                    (u) =>
                      u.firstNames +
                        " " +
                        u.fatherLastName +
                        " | " +
                        u.rut +
                        "-" +
                        u.dv ===
                      value
                  )?.rut ?? null;

                setTitle({ ...title });
              }}
              renderInput={(params) => (
                <TextField {...params} labelId="validador-label" />
              )}
            />
            </FormControl>
          </Grid>
          <Grid size={{ xs: 12, lg: 12 }} display={{ xs: "block", lg: "none" }}></Grid>
          <Grid size={{ xs: 12, lg: 4 }}>
            <FormControl sx={{ m: 0, width: "100%" }}>
              <InputLabel id="superior-label" sx={{ transform: 'translate(4px, -23px)' }}>Superior</InputLabel>
            <Autocomplete
              size="large"
              disablePortal
              options={titles.map(
                (option) => `${option.name} | ${option.persistentId}`
              )}
                sx={act}
              value={(() => {
                let tts = titles.find((t) => t.persistentId === title.manager);
                if (!tts) return "";
                return `${tts.name} | ${tts.persistentId}`;
              })()}
              onChange={(e, value) => {
                try {
                  title.manager = !value
                    ? ""
                    : parseInt(value.split(" | ").pop());

                  setTitle({ ...title });
                } catch {
                  title.manager = "";
                }
              }}
              renderInput={(params) => (
                <TextField {...params} labelId="superior-label" />
              )}
            />
            </FormControl>
          </Grid>

          <Grid size={{ xs: 12, lg: 12 }}> </Grid>
          <Grid size={{ xs: 12, lg: 12 }}>
            <FormControl sx={{ m: 0, width: "100%" }}>
              <InputLabel id="educacion-label" sx={{ transform: 'translate(4px, -23px)' }}>Educacion</InputLabel>
            <TextField
              id="Educacion"
              fullWidth
                labelId="educacion-label"
                variant="outlined"
              value={title.education}
              multiline
                sx={{
                  "& .css-a2jdju-MuiInputBase-root-MuiOutlinedInput-root": {
                    height: "inherit",
                  }
                }}
              minRows={1}
              maxRows={5}
              onChange={(e) => {
                title.education = e.target.value;
                setTitle({ ...title });
              }}
            />
            </FormControl>

          </Grid>


          <Grid size={{ xs: 12, lg: 12 }}> </Grid>
          <Grid size={{ xs: 12, lg: 12 }}>
            <FormControl sx={{ m: 0, width: "100%" }}>
              <InputLabel id="anos-experiencia-label" sx={{ transform: 'translate(4px, -23px)' }}>Años de Experiencia</InputLabel>
            <TextField
              id="YearsOfExperience"
              fullWidth

                labelId="anos-experiencia-label"
                variant="outlined"
              value={title.yearsOfExperience}
              onChange={(e) => {
                let val = e.target.value.replace(/[^0-9]/g, "");
                val = parseInt(val);
                title.yearsOfExperience = isNaN(val) ? 0 : val;
                setTitle({ ...title });
              }}
            />
            </FormControl>
          </Grid>

          <Grid size={{ xs: 12, lg: 12 }}> </Grid>
          <Grid size={{ xs: 12, lg: 12 }}>
            <FormControl sx={{ m: 0, width: "100%" }}>
              <InputLabel id="competencias-label" sx={{ transform: 'translate(4px, -23px)' }}>Competencias</InputLabel>
            <TextField
              id="Competencias"
              fullWidth
                labelId="competencias-label"
                variant="outlined"
              value={title.competencies}
              multiline
                sx={{
                  "& .css-a2jdju-MuiInputBase-root-MuiOutlinedInput-root": {
                    height: "inherit",
                  }
                }}
              minRows={1}
              maxRows={5}
              onChange={(e) => {
                title.competencies = e.target.value;
                setTitle({ ...title });
              }}
            />
            </FormControl>
          </Grid>

          <Grid size={{ xs: 12, lg: 12 }}> </Grid>
          <Grid size={{ xs: 12, lg: 12 }}>
            <FormControl sx={{ m: 0, width: "100%" }}>
              <InputLabel id="funciones-label" sx={{ transform: 'translate(4px, -23px)' }}>Funciones</InputLabel>
            <TextField
              id="functions"
              fullWidth
                labelId="funciones-label"
                variant="outlined"
              value={title.functions}
              multiline
                sx={{
                  "& .css-a2jdju-MuiInputBase-root-MuiOutlinedInput-root": {
                    height: "inherit",
                  }
                }}
              minRows={1}
              maxRows={5}
              onChange={(e) => {
                title.functions = e.target.value;
                setTitle({ ...title });
              }}
            />
            </FormControl>
          </Grid>

          <Grid size={{ xs: 12, lg: 12 }}> </Grid>
          <Grid size={{ xs: 12, lg: 4 }}>
            <FormControl sx={{ m: 0, width: "100%" }}>
              <InputLabel id="autoridad-label" sx={{ transform: 'translate(4px, -23px)' }}>Autoridad</InputLabel>
            <Autocomplete
              size="large"
              disablePortal
              options={optsAuthority}
              value={optsAuthority[title.authority] ?? optsAuthority[2]}
              onChange={(e, value) => {
                title.authority = optsAuthority.indexOf(value);
                setTitle({ ...title });
              }}
                sx={act}
              renderInput={(params) => (
                <TextField {...params} labelId="autoridad-label" />
              )}
            />
            </FormControl>
          </Grid>

          <Grid size={{ xs: 12, lg: 12 }} display={{ xs: "block", lg: "none" }}></Grid>

          <Grid size={{ xs: 12, lg: 8 }}>
            <FormControl sx={{ m: 0, width: "100%" }}>
              <InputLabel id="descripcion-autoridad-label" sx={{ transform: 'translate(4px, -23px)' }}>Descripcion de Autoridad</InputLabel>
            <TextField
              id="authorityDescription"
              fullWidth
                labelId="descripcion-autoridad-label"
                variant="outlined"
              value={title.authorityDescription}
              multiline
                sx={{
                  "& .css-a2jdju-MuiInputBase-root-MuiOutlinedInput-root": {
                    height: "inherit",
                  }
                }}
              minRows={1}
              maxRows={5}
              onChange={(e) => {
                title.authorityDescription = e.target.value;
                setTitle({ ...title });
              }}
            />
            </FormControl>
          </Grid>

          <Grid container justifyContent="flex-end" size={{ xs: 12, lg: 12 }}>

            <Button
              variant="text"
              onClick={() => {
                handleCancel();
              }}
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                handleSave(title);
              }}
            >
              Guardar
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
}
