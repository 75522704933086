import * as React from "react";
import { Modal, Box, Typography, TextField, Button } from "@mui/material";
import Grid from "@mui/material/Grid2";
import Autocomplete from "@mui/material/Autocomplete";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import act from "../../../utils/autoCompleteTheme";

export default function EditDepartmentModal({
  department,
  openState,
  handleCancel,
  handleSave,
  users,
}) {
  const [departmentTemp, setDepartmentTemp] = React.useState(department);

  React.useEffect(() => {
    setDepartmentTemp(department);
  }, [department, setDepartmentTemp]);

  const handleTmp = (department) => {
    setDepartmentTemp({ ...department });
  };

  if (!department || !departmentTemp) return null;

  return (
    <Modal
      open={openState}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          border: "0.1px solid #FFFFFF4B",
          borderRadius: 1,
          boxShadow: 24,
          width: { xs: "90%", md: 700 },
          maxHeight: { xs: "90%", md: "80%" },
          p: {
            xs: 2,
            md: 3,
          },
          overflowY: "scroll",
        }}
      >
        <Grid container spacing={2} columns={12}>
          <Grid size={{ xs: 12, lg: 12 }}>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              size={{ xs: 12 }}
            >
              Editando Departamento{" "}
              {`${department.id ?? 0} - ${department.name ?? ""}`}
            </Typography>
          </Grid>

          <Grid size={{ xs: 12, lg: 12 }}></Grid>
          <Grid size={{ xs: 8, lg: 8 }}>
            <FormControl sx={{ m: 0, width: "100%" }}>
              <InputLabel id="name-label" sx={{ transform: 'translate(4px, -23px)' }}>Nombre</InputLabel>
            <TextField
              id="Nombre"
              fullWidth
                labelId="name-label"
                variant="outlined"
              value={department.name}
              onChange={(e) => {
                department.name = e.target.value;
                handleTmp({ ...department });
              }}
            />
            </FormControl>
          </Grid>
          <Grid size={{ xs: 4, lg: 4 }}>
            <FormControl sx={{ m: 0, width: "100%" }}>
              <InputLabel id="code-label" sx={{ transform: 'translate(4px, -23px)' }}>Sigla</InputLabel>
            <TextField
              id="Sigla"
              fullWidth
                labelId="code-label"
                variant="outlined"
              value={department.code}
              onChange={(e) => {
                department.code = e.target.value;
                handleTmp({ ...department });
              }}
            />
            </FormControl>
          </Grid>

          <Grid size={{ xs: 12, lg: 12 }}></Grid>
          <Grid size={{ xs: 8, lg: 8 }}>
            <FormControl sx={{ m: 0, width: "100%" }}>
              <InputLabel id="erpname-label" sx={{ transform: 'translate(4px, -23px)' }}>Nombre ERP</InputLabel>
            <TextField
              id="Nombre ERP"
              fullWidth
                labelId="erpname-label"
                variant="outlined"
              value={department.erpName}
              onChange={(e) => {
                department.erpName = e.target.value;
                handleTmp({ ...department });
              }}
            />
            </FormControl>
          </Grid>
          <Grid size={{ xs: 4, lg: 4 }}>
            <FormControl sx={{ m: 0, width: "100%" }}>
              <InputLabel id="erpcode-label" sx={{ transform: 'translate(4px, -23px)' }}>Sigla ERP</InputLabel>
            <TextField
              id="Sigla ERP"
              fullWidth
                labelId="erpcode-label"
                variant="outlined"
              value={department.erpCode}
              onChange={(e) => {
                department.erpCode = e.target.value;
                handleTmp({ ...department });
              }}
            />
            </FormControl>
          </Grid>

          <Grid size={{ xs: 12, lg: 12 }}></Grid>
          <Grid size={{ xs: 12, lg: 12 }}>
            <FormControl sx={{ m: 0, width: "100%" }}>
              <InputLabel id="desc-label" sx={{ transform: 'translate(4px, -23px)' }}>Descripcion</InputLabel>
            <TextField
              id="description"
              fullWidth
                labelId="desc-label"
                variant="outlined"
              value={department.description}
              onChange={(e) => {
                department.description = e.target.value;
                handleTmp({ ...department });
              }}
            />
            </FormControl>
          </Grid>

          <Grid size={{ xs: 12, lg: 12 }}></Grid>
          <Grid size={{ xs: 12, lg: 12 }}>
            <FormControl sx={{ m: 0, width: "100%" }}>
              <InputLabel id="manager-label" sx={{ transform: 'translate(4px, -23px)' }}>Supervisor</InputLabel>
            <Autocomplete
              size="large"
              disablePortal
                sx={act}
              options={users.map(
                (option) =>
                  option.firstNames.split(" ")[0] +
                  " " +
                  option.fatherLastName +
                  " | " +
                  option.rut +
                  "-" +
                  option.dv
              )}
              value={(() => {
                let user = users.find((u) => u.rut === department.manager?.rut);
                if (!user) return null;
                return (
                  user.firstNames.split(" ")[0] +
                  " " +
                  user.fatherLastName +
                  " | " +
                  user.rut +
                  "-" +
                  user.dv
                );
              })()}
              onChange={(e, value) => {
                let newManager =
                  users.find((u) => {
                    let str = `${u.firstNames.split(" ")[0]} ${
                      u.fatherLastName
                      } | ${u.rut}-${u.dv}`;
                    return str === value;
                  }) ?? null;

                department.manager = newManager;

                handleTmp({ ...department });
              }}
              renderInput={(params) => (
                <TextField {...params} labelId="manager-label" />
              )}
            />
            </FormControl>
          </Grid>
          {/* LINE 3 */}
          <Grid container justifyContent="flex-end" size={{ xs: 12, lg: 12 }}>
            <Button
              variant="text"
              onClick={() => {
                handleCancel();
              }}
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                handleSave(department, "put");
              }}
            >
              Guardar
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
}
