import * as React from "react";
import clsx from "clsx";
import { animated, useSpring } from "@react-spring/web";

import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Collapse from "@mui/material/Collapse";
import Typography from "@mui/material/Typography";
import { RichTreeView } from "@mui/x-tree-view/RichTreeView";
import { unstable_useTreeItem2 as useTreeItem2 } from "@mui/x-tree-view/useTreeItem2";
import {
  TreeItem2Content,
  TreeItem2IconContainer,
  TreeItem2Label,
  TreeItem2Root,
} from "@mui/x-tree-view/TreeItem2";
import { TreeItem2Icon } from "@mui/x-tree-view/TreeItem2Icon";
import { TreeItem2Provider } from "@mui/x-tree-view/TreeItem2Provider";

import { useTheme } from "@mui/material/styles";

let id = -1;

function DotIcon({ color }) {
  return (
    <Box sx={{ marginRight: 1, display: "flex", alignItems: "center" }}>
      <svg width={6} height={6}>
        <circle cx={3} cy={3} r={3} fill={color} />
      </svg>
    </Box>
  );
}

const AnimatedCollapse = animated(Collapse);

function TransitionComponent(props) {
  const style = useSpring({
    to: {
      opacity: props.in ? 1 : 0,
      transform: `translate3d(0,${props.in ? 0 : 20}px,0)`,
    },
  });

  return <AnimatedCollapse style={style} {...props} />;
}

function CustomLabel({ color, expandable, children, ...other }) {
  const theme = useTheme();
  const colors = {
    blue: (theme.vars || theme).palette.primary.main,
    green: (theme.vars || theme).palette.success.main,
  };

  const iconColor = color ? colors[color] : null;
  return (
    <TreeItem2Label {...other} sx={{ display: "flex", alignItems: "center" }}>
      {iconColor && <DotIcon color={iconColor} />}
      <Typography
        className="labelText"
        variant="body2"
        sx={{ color: "text.primary" }}
      >
        {children}
      </Typography>
    </TreeItem2Label>
  );
}

const CustomTreeItem = React.forwardRef(function CustomTreeItem(props, ref) {
  const { id, itemId, label, disabled, children, ...other } = props;

  const {
    getRootProps,
    getContentProps,
    getIconContainerProps,
    getLabelProps,
    getGroupTransitionProps,
    status,
    publicAPI,
  } = useTreeItem2({ id, itemId, children, label, disabled, rootRef: ref });

  const item = publicAPI.getItem(itemId.toString());
  const color = item?.color;
  return (
    <TreeItem2Provider itemId={itemId.toString()}>
      <TreeItem2Root {...getRootProps(other)}>
        <TreeItem2Content
          {...getContentProps({
            className: clsx("content", {
              expanded: status.expanded,
              selected: status.selected,
              focused: status.focused,
              disabled: status.disabled,
            }),
          })}
        >
          {status.expandable && (
            <TreeItem2IconContainer {...getIconContainerProps()}>
              <TreeItem2Icon status={status} />
            </TreeItem2IconContainer>
          )}

          <CustomLabel {...getLabelProps({ color })} />
        </TreeItem2Content>
        {children && (
          <TransitionComponent
            {...getGroupTransitionProps({ className: "groupTransition" })}
          />
        )}
      </TreeItem2Root>
    </TreeItem2Provider>
  );
});

function incrementId(text) {
  id++;
  return `${text}-${id}`;
}

export default function SDOUserThree({ users }) {
  let findChild = (user, users) => {
    let children = users.filter((u) => u?.manager?.rut === user.rut);
    if (children.length === 0 || !children) return;

    let returnable = children.map((u) => {
      return {
        id: incrementId(`${user.rut}${u.rut}`),
        label: `${u.firstNames} ${u.fatherLastName} ${u.motherLastName}`,
        children: findChild(u, users) ?? undefined,
      };
    });

    return returnable;
  };

  let rootUsers = users.filter(
    (user) => !user.manager
  );

  let items = rootUsers.map((user) => {
    return {
      id: user.rut,
      label: `${user.firstNames} ${user.fatherLastName} ${user.motherLastName}`,
      children:
        findChild(
          user,
          users.filter((u) => u.rut !== user.rut)
        ) ?? undefined,
    };
  });

  return (
    <Card
      variant="outlined"
      sx={{ display: "flex", flexDirection: "column", gap: "8px", flexGrow: 1 }}
    >
      <CardContent>
        <Typography component="h2" variant="subtitle2">
          Organigrama
        </Typography>
        <RichTreeView
          items={items}
          aria-label="pages"
          defaultExpandedItems={[]}
          defaultSelectedItems={[]}
          sx={{
            m: "0 -8px",
            pb: "8px",
            height: "fit-content",
            flexGrow: 1,
            overflowY: "auto",
          }}
          slots={{ item: CustomTreeItem }}
        />
      </CardContent>
    </Card>
  );
}
