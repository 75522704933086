import * as React from "react";
import { Modal, Box, Typography, Button } from "@mui/material";
import Grid from "@mui/material/Grid2";
import TextField from "@mui/material/TextField";
import {
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Chip from "@mui/material/Chip";

export default function EditTaskModal({
  task,
  titles,
  openState,
  handleCancel,
  handleSave
}) {
  const [openTask, setOpenTask] = React.useState(task);
  const [allowed, setAllowed] = React.useState(task ? task?.titles?.map((m) => m.persistentId) : []);

  const theme = useTheme();

  React.useEffect(() => {
    if (!task) return;

    setOpenTask(task);
    setAllowed(task.titles.map((m) => m.persistentId));

  }, [task, setOpenTask, titles]);

  const handleTmp = (_task) => {
    setOpenTask({ ..._task });
  };

  if (!task || !openTask) return null;

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  function getStyles(name, personName, theme) {
    return {
      fontWeight: personName.includes(name)
        ? theme.typography.fontWeightMedium
        : theme.typography.fontWeightRegular,
    };
  }

  return (
    <Modal
      open={openState}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          border: "0.1px solid #FFFFFF4B",
          borderRadius: 1,
          boxShadow: 24,
          "&::-webkit-scrollbar": {
            width: "0px", // smaller width for the scrollbar track
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#888", // color of the scrollbar thumb
            borderRadius: "4px", // rounder corners
            margin: "20px 0", // att
          },
          width: { xs: "90%", md: 700 },
          maxHeight: { xs: "90%", md: "80%" },
          p: {
            xs: 2,
            md: 3,
          },
          overflowY: "scroll",
        }}
      >
        <Grid container spacing={2} columns={12}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            size={{ xs: 12 }}
          >
            Editando {openTask.title}
          </Typography>

          <Grid size={{ xs: 12, lg: 12 }}></Grid>
          <Grid size={{ xs: 12, lg: 12 }}>
            <FormControl sx={{ m: 0, width: "100%" }}>
              <InputLabel id="title-label" sx={{ transform: 'translate(4px, -23px)' }}>Nombre de la plantilla de tarea</InputLabel>
              <TextField
                id="task-title"
                fullWidth
                labelId="title-label"
                variant="outlined"
                value={openTask.title}
                onChange={(e) => {
                  openTask.title = e.target.value;
                  handleTmp(openTask);
                }}
              />
            </FormControl>
          </Grid>

          <Grid size={{ xs: 12, lg: 12 }}></Grid>
          <Grid size={{ xs: 12, lg: 12 }}>
            <FormControl sx={{ m: 0, width: "100%" }}>
              <InputLabel id="description-label" sx={{ transform: 'translate(4px, -23px)' }}>Descripciond de la tarea</InputLabel>
              <TextField
                id="task-description"
                fullWidth
                labelId="description-label"
                variant="outlined"
                value={openTask.description}
                onChange={(e) => {
                  openTask.description = e.target.value;
                  handleTmp(openTask);
                }}
              />
            </FormControl>
          </Grid>
          
          <Grid size={{ xs: 12, lg: 12 }}></Grid>
          <Grid size={{ xs: 12, lg: 12 }}>
            <FormControl sx={{ m: 0, width: "100%" }}>
              <InputLabel id="demo-multiple-name-label" sx={{ transform: 'translate(4px, -23px)' }}>Cargos Autorizados</InputLabel>
              <Select
                labelId="demo-multiple-name-label"
                id="demo-multiple-name"
                multiple
                sx={{
                  height: "inherit",
                }}
                value={allowed}
                onChange={(e) => {
                  setAllowed(e.target.value);
                }}
                input={<OutlinedInput />}
                MenuProps={MenuProps}
                renderValue={(selected) => (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {selected.map((value) => {
                      const title = titles.find((title) => title.persistentId === value);
                      return (
                        <Chip
                          key={value}
                          label={`${title.name} | ${title.persistentId}`}
                        />
                      );
                    })}

                  </Box>
                )}
              >
                {titles.map((title) => (
                  <MenuItem
                    key={title.persistentId}
                    value={title.persistentId}
                    style={getStyles(
                      `${title.name} | ${title.persistentId}`,
                      openTask.titles,
                      theme
                    )}
                  >
                    {`${title.name} | ${title.persistentId}`}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid size={{ xs: 12, lg: 12 }}></Grid>
          <Grid container justifyContent="flex-end" size={{ xs: 12, lg: 12 }}>
            <Button
              variant="text"
              onClick={() => {
                handleCancel();
              }}
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                openTask.titles = allowed
                handleSave(openTask, "put");
              }}
            >
              Guardar
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
}
