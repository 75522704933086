import * as React from "react";
import { Modal, Box, Typography, Button } from "@mui/material";
import Grid from "@mui/material/Grid2";
import TextField from "@mui/material/TextField";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import Select from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import MenuProps from "@mui/material/Menu";
import { useTheme } from "@mui/material/styles";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import act from "../../../utils/autoCompleteTheme";
import Checkbox from "@mui/material/Checkbox";
import DeleteIcon from '@mui/icons-material/Delete';
import Divider from "@mui/material/Divider";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import IconButton from "@mui/material/IconButton";
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Collapse } from "@mui/material";
import { AddOutlined } from "@mui/icons-material";



function getStyles(name, personName, theme) {
  return {
    fontWeight: personName.includes(name)
      ? theme.typography.fontWeightMedium
      : theme.typography.fontWeightRegular,
  };
}


export default function CreateObjectiveModal({
  user,
  users,
  openState,
  handleSave,
  handleCancel,
}) {
  const theme = useTheme();
  const [expanded, setExpanded] = React.useState(false);
  const [obj, setObj] = React.useState({
    title: "",
    startDate: dayjs().toDate(),
    endDate: dayjs().add(3, 'month').toDate(),
    allowedUsers: [],
    evPeriods: [],
  });


  if (!openState) {
    return null;
  }

  return (
    <Modal
      open={openState}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          border: "0.1px solid #FFFFFF4B",
          borderRadius: 1,
          boxShadow: 24,
          "&::-webkit-scrollbar": {
            width: "0px", // smaller width for the scrollbar track
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#888", // color of the scrollbar thumb
            borderRadius: "4px", // rounder corners
            margin: "20px 0", // att
          },
          width: { xs: "90%", md: 700 },
          maxHeight: { xs: "90%", md: "80%" },
          p: {
            xs: 2,
            md: 3,
          },
          overflowY: "scroll",
        }}
      >
        <Grid container spacing={2} columns={12}>
          <Grid size={{ xs: 12, lg: 12 }}>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              size={{ xs: 12 }}
            >
              Creando nuevo Objetivo
            </Typography>
          </Grid>

          <Grid size={{ xs: 12, lg: 12 }}></Grid>
          <Grid size={{ xs: 12, lg: 12 }}>
            <FormControl sx={{ m: 0, width: "100%" }}>
              <InputLabel id="title-label" sx={{ transform: 'translate(4px, -23px)' }}>Nombre de la evaluacion</InputLabel>
            <TextField
              id="Nombre del objetivo"
              fullWidth
                labelId="title-label"
                variant="outlined"
              value={obj.title}
              onChange={(e) => {
                obj.title = e.target.value;
                setObj({ ...obj });
              }}
            />
            </FormControl>
          </Grid>

          <Grid size={{ xs: 12, lg: 12 }}></Grid>

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Grid size={{ xs: 6, lg: 6 }}>
              <FormControl sx={{ m: 0, width: "100%" }}>
                <InputLabel id="start-date-label" sx={{ transform: 'translate(4px, -23px)' }}>Fecha de Inicio</InputLabel>
              <DatePicker
                  sx={act}
                  fullWidth
                  labelId="start-date-label"
                  value={dayjs(obj.startDate)}
                  onChange={(date) => {
                    obj.startDate = date.toISOString();
                    setObj(obj);
                  }}
                />
              </FormControl>
            </Grid>
            <Grid size={{ xs: 6, lg: 6 }}>
              <FormControl sx={{ m: 0, width: "100%" }}>
                <InputLabel id="end-date-label" sx={{ transform: 'translate(4px, -23px)' }}>Fecha de Fin</InputLabel>
                <DatePicker
                  sx={act}
                  fullWidth
                  labelId="end-date-label"
                  value={dayjs(obj.endDate)}
                  onChange={(date) => {
                    obj.endDate = date.toISOString();
                    setObj(obj);
                  }}
                />
              </FormControl>
            </Grid>
          </LocalizationProvider>
          <Grid size={{ xs: 12, lg: 12 }}></Grid>
          <Grid size={{ xs: 12, lg: 12 }}>
            <FormControl sx={{ m: 0, width: "100%" }}>
              <InputLabel id="demo-multiple-name-label" sx={{ transform: 'translate(4px, -23px)' }}>Usuarios Autorizados</InputLabel>
              <Select
                labelId="demo-multiple-name-label"
                id="demo-multiple-name"
                multiple
                sx={{
                  height: "inherit",
                }}
                value={obj.allowedUsers}
                onChange={(e) => {
                  obj.allowedUsers = e.target.value;
                  setObj({ ...obj });
                }}
                input={<OutlinedInput label="Usuarios permitidos" />}
                MenuProps={MenuProps}
                renderValue={(selected) => (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {selected.map((value) => {
                      const user = users.find((user) => user.rut === value);
                      return (
                        <Chip
                          key={value}
                          label={`${user.firstNames} ${user.fatherLastName} ${user.motherLastName}`}
                        />
                      );
                    })}
                  </Box>
                )}
              >
                {users.map((user) => (
                  <MenuItem
                    key={user.rut}
                    value={user.rut}
                    style={getStyles(
                      `${user.firstNames} ${user.fatherLastName} ${user.motherLastName} | ${user.rut}-${user.dv}`,
                      obj.allowedUsers,
                      theme
                    )}
                  >
                    {`${user.firstNames} ${user.fatherLastName} ${user.motherLastName} | ${user.rut}-${user.dv}`}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid size={{ xs: 12, lg: 12 }}></Grid>

          <Grid
            size={{ xs: 12, lg: 6 }}
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <Typography
              id="evp-subt"
              variant="h6"
              component="h5"
              size={{ xs: 12 }}
            >
              Periodos de evaluacion
            </Typography>
          </Grid>

          <Grid container justifyContent="flex-end" size={{ xs: 12, lg: 6 }}>
            <Button
              variant="text"
              startIcon={<AddOutlined sx={{
              }} />}
              sx={{
                backgroundColor: 'transparent',
                color: '#2F84D3FF',
                border: '1px solid #2F84D3FF',
                '&:hover': {
                  backgroundColor: '#2F84D3FF',
                  color: 'white',
                  border: '1px solid white',
                },
              }}
              onClick={() => {
                obj.evPeriods.push({
                  title: "Periodo " + (obj.evPeriods.length + 1),
                  description: "",
                  startDate: dayjs().toDate(),
                  endDate: dayjs().add(1, 'month').toDate(),
                  allowEvaluatorChanges: true,
                  allowSubjectChanges: true,
                  notify: true,
                });
                setExpanded(obj.evPeriods.length - 1);
                setObj({ ...obj });
              }}
            >
              Agregar Periodo
            </Button>
          </Grid>

          <Grid size={{ xs: 12, lg: 12 }}>
            {
              obj.evPeriods.map((period, index) => {
                return (
                  <>
                    <Accordion sx={{
                      width: "100%",
                      margin: 0,
                      backgroundColor: "transparent",
                      border: "none",
                      "& > h3 > button > span": {
                        justifyContent: "space-between",
                        alignItems: "center",
                        margin: "0px",
                        padding: "0px"
                      }
                    }} key={index}
                      expanded={index === expanded}>
                      <AccordionSummary
                        onClick={() => {
                          setExpanded(index === expanded ? false : index);
                        }}>
                        <Collapse
                          in={expanded !== index}
                          timeout={{ enter: 600, exit: 600 }}
                        >
                          <Typography
                            id="evp-subt"
                            variant="h6"
                            component="h5"
                            sx={{ mt: 1, fontWeight: 600, fontSize: "1rem" }}
                          >
                            {obj.evPeriods[index].title}
                          </Typography>
                        </Collapse>
                        <div>
                          <IconButton
                            size="small"
                            sx={{
                              p: 0, // remove padding
                              minWidth: 'auto', // remove the default minimum width
                              backgroundColor: 'transparent',
                              color: "#888888",
                              border: "none",
                              '&:hover': {
                                color: 'black',
                                border: '1px solid 888888',
                              },
                            }}
                            onClick={(e) => {
                              e.stopPropagation();
                              setExpanded(index === expanded ? false : index);
                            }}
                          >
                            {index === expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                          </IconButton>
                          <IconButton
                            size="small"
                            sx={{
                              p: 0, // remove padding
                              minWidth: 'auto', // remove the default minimum width
                              backgroundColor: 'transparent',
                              color: '#d32f2f',
                              '&:hover': {
                                backgroundColor: '#d32f2f',
                                color: 'white',
                                border: '1px solid white',
                              },
                            }}
                            onClick={(e) => {
                              e.stopPropagation();
                              obj.evPeriods.splice(index, 1);
                              if (index === expanded) {
                                setExpanded(false);
                              } else if (index >= 0) {
                                if (obj.evPeriods.length > 0) {
                                  if (expanded > index) {
                                    setExpanded(expanded - 1);
                                  } else {
                                    setExpanded(expanded);
                                  }
                                } else {
                                  setExpanded(false);
                                }
                              } else {
                                setExpanded(false);
                              }

                              setObj({ ...obj });
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </div>
                      </AccordionSummary>
                      <AccordionDetails size={{ xs: 12, lg: 12 }}>
                        <Grid container spacing={2} columns={12}>
                          <Grid size={{ xs: 12, lg: 12 }}>
                            <FormControl sx={{ m: 0, width: "100%" }}>
                              <InputLabel sx={{ transform: 'translate(4px, -23px)' }}>Nombre del Periodo</InputLabel>
                              <TextField
                                id={"nombre del objetivo " + (index + 1)}
                                fullWidth
                                variant="outlined"
                                value={obj.evPeriods[index].title}
                                onChange={(e) => {
                                  obj.evPeriods[index].title = e.target.value;
                                  setObj({ ...obj });
                                }}
                              />
                            </FormControl>
                          </Grid>
                          <Grid size={{ xs: 12, lg: 12 }}></Grid>
                          <Grid size={{ xs: 12, lg: 12 }}>
                            <FormControl sx={{ m: 0, width: "100%" }}>
                              <InputLabel sx={{ transform: 'translate(4px, -23px)' }}>Descripcion</InputLabel>
                              <TextField
                                id={"descripcion del objetivo " + (index + 1)}
                                fullWidth
                                labelId="dx-auth-label"
                                variant="outlined"
                                value={obj.evPeriods[index].description}
                                sx={{
                                  "& .css-a2jdju-MuiInputBase-root-MuiOutlinedInput-root": {
                                    height: "inherit",
                                  }
                                }}
                                multiline
                                minRows={1}
                                maxRows={5}
                                onChange={(e) => {
                                  obj.evPeriods[index].description = e.target.value;
                                  setObj({ ...obj });
                                }}
                              />
                            </FormControl>
                          </Grid>
                          <Grid size={{ xs: 12, lg: 12 }}></Grid>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <Grid size={{ xs: 6, lg: 6 }}>
                              <FormControl sx={{ m: 0, width: "100%" }}>
                                <InputLabel id="start-date-label" sx={{ transform: 'translate(4px, -23px)' }}>Fecha de Inicio</InputLabel>
                                <DatePicker
                                  sx={act}
                                  fullWidth
                                  labelId="start-date-label"
                                  value={dayjs(obj.evPeriods[index].startDate)}
                                  onChange={(date) => {
                                    obj.evPeriods[index].startDate = date.toISOString();
                                    setObj({ ...obj });
                                  }}
                                />
                              </FormControl>
                            </Grid>
                            <Grid size={{ xs: 6, lg: 6 }}>
                              <FormControl sx={{ m: 0, width: "100%" }}>
                                <InputLabel id="end-date-label" sx={{ transform: 'translate(4px, -23px)' }}>Fecha de Fin</InputLabel>
                                <DatePicker
                                  sx={act}
                                  fullWidth
                                  labelId="end-date-label"
                                  value={dayjs(obj.evPeriods[index].endDate)}
                                  onChange={(date) => {
                                    obj.evPeriods[index].endDate = date.toISOString();
                                    setObj({ ...obj });
                                  }}
                                />
                              </FormControl>
                            </Grid>
                          </LocalizationProvider>

                          <Grid size={{ xs: 3, lg: 3 }}>
                            <FormControl sx={{ m: 0, width: "100%" }}>
                              <InputLabel sx={{ transform: 'translate(36px, 10px)' }}>Permitir Evaluacion</InputLabel>
                              <Checkbox
                                checked={obj.evPeriods[index].allowEvaluatorChanges}
                                onChange={(e) => {
                                  obj.evPeriods[index].allowEvaluatorChanges = e.target.checked;
                                  setObj({ ...obj });
                                }}
                              />
                            </FormControl>
                          </Grid>
                          <Grid size={{ xs: 3, lg: 3 }}>
                            <FormControl sx={{ m: 0, width: "100%" }}>
                              <InputLabel sx={{ transform: 'translate(36px, 10px)' }}>Permitir Edicion</InputLabel>
                              <Checkbox
                                checked={obj.evPeriods[index].allowSubjectChanges}
                                onChange={(e) => {
                                  obj.evPeriods[index].allowSubjectChanges = e.target.checked;
                                  setObj({ ...obj });
                                }}
                              />
                            </FormControl>
                          </Grid>
                          <Grid size={{ xs: 3, lg: 3 }}>
                            <FormControl disabled sx={{ m: 0, width: "100%" }}>
                              <InputLabel sx={{ transform: 'translate(36px, 10px)' }}>Notificar</InputLabel>
                              <Checkbox
                                checked={obj.evPeriods[index].notify && false}
                                onChange={(e) => {
                                  obj.evPeriods[index].notify = e.target.checked;
                                  setObj({ ...obj });
                                }}
                              />
                            </FormControl>
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion >
                    {
                      index !== obj.evPeriods.length - 1 ? <Divider sx={{
                        height: '0.5px',
                        width: "100%",
                        backgroundColor: '#88888857',
                        border: 'none',
                        borderRadius: "100px",
                        "&:hover": {
                          height: '0.5px',
                          width: "100%",
                          backgroundColor: '#88888857',
                          border: 'none',
                          borderRadius: "100px"
                        }
                      }} /> : null
                    }

                  </>
                );
              })
            }
          </Grid>



          <Grid container justifyContent="flex-end" size={{ xs: 12, lg: 12 }}>
            <Button
              variant="text"
              onClick={() => {
                handleCancel();
              }}
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              onClick={() => {

                handleSave(obj, "post");
              }}
            >
              Guardar
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
}