import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import NoPage from "./pages/NoPage";
import Login from "./pages/Login";
import ErrorPage from "./pages/ErrorPage";
import ForgotPassword from "./pages/ForgotPassword";
import HomeTemplate from "./pages/HomeTemplate";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import ResetPassword from "./pages/ResetPassword";
import SdoPage from "./modules/sdo/pages/SdoPage";
import SedPage from "./modules/sed/pages/SedPage";
import VersionBadge from "./utils/VersionBadge";
import NoAuthPage from "./pages/NoAuthPage";

export default function App() {
  localStorage.setItem("systemTabs", [
    { text: "Home", icon: <HomeRoundedIcon /> },
  ]);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />}></Route>
        <Route path="login" element={<Login />}></Route>

        <Route path="SDO" element={<SdoPage />}></Route>
        <Route path="SDO/:subSystem" element={<SdoPage />}></Route>

        <Route path="SED" element={<SedPage />}></Route>
        <Route path="SED/:subSystem" element={<SedPage />}></Route>

        <Route path="INT" element={<HomeTemplate />}></Route>

        <Route path="noauth" element={<NoAuthPage />}></Route>

        <Route path="forgot" element={<ForgotPassword />}></Route>
        <Route path="reset" element={<ResetPassword />}></Route>
        <Route path="u/:username" element={<NoPage />}></Route>
        <Route path="*" element={<ErrorPage />}></Route>
      </Routes>
      <VersionBadge />
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);
