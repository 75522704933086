import { useState, React } from "react";
import { DataGrid } from "@mui/x-data-grid";
import formatDate from "../../../utils/formatDate";
import { Edit, OpenInFull } from "@mui/icons-material";
import hasPerms from "../../../utils/hasPerms";
import EditObjectiveModal from "./EditObjectiveModal";
import CreateObjectiveModal from "./CreateObjectiveModal";
import api from "../../../utils/api";


export default function SEDObjectivesTable({
  user,
  users,
  objectives,
  showCreate,
  setShowCreate,
  parentReRender,
  setLoading,
}) {
  const [showEdit, setShowEdit] = useState(false);
  const [selectedObjective, setSelectedObjective] = useState(null);

  const handleOpen = (id) => {
    let objCopy = { ...objectives.find((obj) => obj.id === id) };
    setSelectedObjective(objCopy);
    setShowEdit(true);
  };

  const saveChanges = async (objective, type = "post") => {
    setShowCreate(false);

    let res = null;

    try {
      objective.evPeriods = objective.evPeriods ?? []
      objective.allowedUsers = objective.allowedUsers ?? []
      delete objective.evaluatedPeriods;

      if (type === "post") {
        res = await api.post("/objective/full", objective, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
          },
        });

      } else if (type === "put") {
        res = await api.put("/objective/full/" + objective.id, objective, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
          },
        });

      } else {
        throw new Error("Invalid type for saveChanges function");
      }

      if (!res) {
        throw new Error("No response from server");
      }

      setShowCreate(false);
      setShowEdit(false);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
    parentReRender();
  };

  let columns = [
    {
      field: "ID",
      headerName: "ID",
      sortable: true,
      width: 30,
      valueGetter: (value, row) => `${row.id}`,
    },
    {
      field: "title",
      headerName: "Nombre del Objetivo",
      width: 225,
      valueGetter: (value, row) => `${row.title}`,
    },
    {
      field: "userCount",
      headerName: "Usuarios Asignados",
      width: 100,
      valueGetter: (value, row) => {
        return `${row.allowedUsers.length}`;
      },
    },
    {
      field: "periodsCount",
      headerName: "Periodos Asignados",
      width: 100,
      valueGetter: (value, row) => `${row.evaluatedPeriods.length}`,
    },
    {
      field: "startDate",
      headerName: "Fecha de Inicio",
      width: 100,
      valueGetter: (value, row) => `${formatDate(row.startDate)}`,
    },
    {
      field: "endDate",
      headerName: "Fecha de Fin",
      width: 100,
      valueGetter: (value, row) => `${formatDate(row.endDate)}`,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => {
        return [
          hasPerms(user, "SED.Objetivo.Editar") && objectives.filter((obj) => obj.id === id)[0].evaluator?.rut === user.rut && (
            <OpenInFull onClick={() => handleOpen(id)} />
          ),
          hasPerms(user, "SED.Objetivo.Editar") && objectives.filter((obj) => obj.id === id)[0].evaluator?.rut === user.rut && (
            <Edit onClick={() => handleOpen(id)} />
          ),
          (objectives.filter((obj) => obj.id === id)[0].allowedUsers).filter((u) => u.rut === user.rut).length > 0 && (
            <OpenInFull onClick={() => { }} />
          ),
        ].filter(Boolean); // Filter out undefined values
      },
    },
  ];

  return (
    <>
      <DataGrid
        rows={objectives}
        columns={columns}
        getRowId={(row) => row.id}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize:
                users.length === 0
                  ? 20
                  : users.length <= 20
                    ? users.length
                    : 20,
            },
          },
        }}
        disableColumnResize
        pageSizeOptions={[users.length, 10, 20, 50]}
        density="compact"
      />
      <CreateObjectiveModal
        user={user}
        users={users}
        openState={showCreate}
        handleSave={saveChanges}
        handleCancel={() => setShowCreate(false)}
      />
      <EditObjectiveModal
        user={user}
        users={users}
        objective={selectedObjective}
        openState={showEdit}
        handleCancel={() => setShowEdit(false)}
        handleSave={saveChanges}
      />
    </>
  );
}