import * as React from "react";
import { useEffect, useState } from "react";
import { alpha } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import AppNavbar from "../../../components/AppNavbar";
import Header from "../../../components/Header";
import SideMenu from "../../../components/SideMenu";
import AppTheme from "../../../theme/AppTheme";
import {
  chartsCustomizations,
  dataGridCustomizations,
  treeViewCustomizations,
} from "../../../theme/customizations";
import PeopleRoundedIcon from "@mui/icons-material/PeopleRounded";
import { BadgeRounded, FactoryRounded } from "@mui/icons-material";
import { useParams, useNavigate } from "react-router-dom";
import api from "../../../utils/api";
import SdoUsersPage from "./SdoUsersPage";
import SdoTitlesPage from "./SdoTitlesPage";
import SdoDepartmentsPage from "./SdoDepartmentsPage";
import CircularProgress from "@mui/material/CircularProgress";

const xThemeComponents = {
  ...chartsCustomizations,
  ...dataGridCustomizations,
  ...treeViewCustomizations,
};

export default function SdoPage() {
  const { subSystem } = useParams();
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [titles, setTitles] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [perms, setPerms] = useState([]);

  useEffect(() => {
    if (!subSystem) {
      navigate("/SDO/Usuarios");
    }
  }, [subSystem, navigate]);

  const fetchData = React.useCallback(async () => {
    setLoading(true);
    try {
      const res = await api.get("/user/me", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      });

      const users = await api.get("/user", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      });

      const titles = await api.get("/title", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      });

      const departments = await api.get("/department", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      });

      const perms = await api.get("/permissions/modules", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      });

      setUser(res.data);
      setUsers(users.data);
      setTitles(titles.data);
      setDepartments(departments.data);
      setPerms(perms.data);
    } catch (e) {
      navigate("/login");
    }
    setLoading(false);
  }, [setUser, setUsers, setTitles, setDepartments, setPerms, navigate]);

  useEffect(() => {
    fetchData();
  }, [subSystem, navigate, fetchData]);

  const reRender = fetchData;

  let sidePropsName = user?.firstNames?.split(" ")[0] + " " + user?.fatherLastName
  let sidePropsEmail = user?.email ?? ""
  let sidePropsAllowedPaths = user?.permissions?.map((x) => x.system) ?? []
  let sidePropsMenuItems = [
    { text: "Usuarios", to: "Usuarios", icon: <PeopleRoundedIcon /> },
    { text: "Cargos", to: "Cargos", icon: <BadgeRounded /> },
    {
      text: "Departamentos",
      to: "Departamentos",
      icon: <FactoryRounded />,
    },
  ]
  let sidePropsOnClick = (to) => {
    navigate("/SDO/" + to);
  }
  let sidePropsSelected = subSystem

  return (
    <AppTheme themeComponents={xThemeComponents}>
      <CssBaseline enableColorScheme />
      <Box sx={{ display: "flex" }}>
        <SideMenu
          name={sidePropsName}
          email={sidePropsEmail}
          allowedPaths={sidePropsAllowedPaths}
          menuItems={sidePropsMenuItems}
          onClick={sidePropsOnClick}
          selected={sidePropsSelected}
        />
        <AppNavbar
          name={sidePropsName}
          email={sidePropsEmail}
          allowedPaths={sidePropsAllowedPaths}
          menuItems={sidePropsMenuItems}
          onClick={sidePropsOnClick}
          selected={sidePropsSelected}

        />

        <Box
          component="main"
          sx={(theme) => ({
            flexGrow: 1,
            backgroundColor: theme.vars
              ? `rgba(${theme.vars.palette.background.defaultChannel} / 1)`
              : alpha(theme.palette.background.default, 1),
            overflow: "auto",
          })}
        >
          <Stack
            spacing={2}
            sx={{
              alignItems: "center",
              mx: 3,
              pb: 5,
              mt: { xs: 8, md: 0 },
            }}
          >
            <Header />
            {loading && (
              <Box
                position="absolute"
                top={0}
                left={0}
                width="100%"
                height="100%"
                display="flex"
                justifyContent="center"
                alignItems="center"
                bgcolor="rgba(255, 255, 255, 0)"
                zIndex={10}
              >
                <CircularProgress />
              </Box>
            )}
            <Box
              sx={{
                width: "100%",
                maxWidth: { sm: "100%", md: "1700px" },
                filter: loading ? "blur(5px)" : "none",
              }}
            >
              {((sys) => {
                switch (sys) {
                  case "Usuarios":
                    return (
                      <SdoUsersPage
                        user={user}
                        users={users}
                        setUsers={setUsers}
                        titles={titles}
                        setTitles={setTitles}
                        departments={departments}
                        setDepartments={setDepartments}
                        perms={perms}
                        setPerms={setPerms}
                        reRender={reRender}
                        setLoading={setLoading}
                      />
                    );
                  case "Cargos":
                    return (
                      <SdoTitlesPage
                        user={user}
                        users={users}
                        setUsers={setUsers}
                        titles={titles}
                        setTitles={setTitles}
                        departments={departments}
                        setDepartments={setDepartments}
                        perms={perms}
                        setPerms={setPerms}
                        reRender={reRender}
                        setLoading={setLoading}
                      />
                    );
                  case "Departamentos":
                    return (
                      <SdoDepartmentsPage
                        user={user}
                        users={users}
                        setUsers={setUsers}
                        titles={titles}
                        setTitles={setTitles}
                        departments={departments}
                        setDepartments={setDepartments}
                        perms={perms}
                        setPerms={setPerms}
                        reRender={reRender}
                        setLoading={setLoading}
                      />
                    );
                  default:
                    return (
                      <SdoUsersPage
                        user={user}
                        users={users}
                        setUsers={setUsers}
                        titles={titles}
                        setTitles={setTitles}
                        departments={departments}
                        setDepartments={setDepartments}
                        perms={perms}
                        setPerms={setPerms}
                        reRender={reRender}
                        setLoading={setLoading}
                      />
                    );
                }
              })(subSystem)}
            </Box>
          </Stack>
        </Box>
      </Box>
    </AppTheme>
  );
}
