import * as React from "react";
import { useEffect, useState } from "react";
import Grid from "@mui/material/Grid2";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import hasPerms from "../../../utils/hasPerms";
import SEDObjectivesTable from "../components/SEDObjectivesTable";
import { Typography } from "@mui/material";

export default function SedObjectivesPage({
  user,
  users,
  objectives,
  setObjectives,
  reRender,
  setLoading,
}) {
  localStorage.setItem("service", "sed");

  const [search, setSearch] = useState("");
  const [showCreateUser, setShowCreateUser] = useState(false);

  const isCreateButtonVisible = hasPerms(user, "SED.Objetivo.Crear");

  useEffect(() => {
    setLoading(false);
  }, [user, users, setLoading]);

  let fuzzyUserSearch = (users, searchQuery) => {
    if (searchQuery === "") return users;
    let scored = [];
    let tokens = searchQuery.split(" ");

    users.forEach((user) => {
      let score = 0;

      tokens.forEach((token) => {
        if (user.firstNames.toUpperCase().includes(token.toUpperCase()))
          score += 3;
        if (user.fatherLastName.toUpperCase().includes(token.toUpperCase()))
          score += 3;
        if (user.email.toUpperCase().includes(token.toUpperCase())) score += 2;
        if (user.motherLastName.toUpperCase().includes(token.toUpperCase()))
          score += 2;
        if (user.rut.toString().toUpperCase().includes(token.toUpperCase()))
          score += 1.5;
        if (user.department.name.toUpperCase().includes(token.toUpperCase()))
          score += 1;
        if (user.title.name.toUpperCase().includes(token.toUpperCase()))
          score += 1;
        if (
          user.department.description
            .toUpperCase()
            .includes(token.toUpperCase())
        )
          score += 0.5;
        if (user.title.description.toUpperCase().includes(token.toUpperCase()))
          score += 0.5;
      });

      user["score"] = score;
      scored.push(user);
    });

    return scored.sort((a, b) => {
      return b.score - a.score;
    });
  };

  return (
    <>
      <Grid container spacing={2} columns={12}>
        <Grid
          container
          alignItems="center"
          justifyContent={"space-between"}
          sx={{ width: "100%" }}
        >
          {/* Search Bar */}
          <FormControl
            sx={{
              width: { xs: "100%", md: "25ch" },
            }}
            variant="outlined"
          >
            <OutlinedInput
              size="small"
              id="search"
              placeholder="Buscar en Objetivos…"
              onKeyUp={(e) => {
                setSearch(e.target.value);
              }}
              startAdornment={
                <InputAdornment position="start" sx={{ color: "text.primary" }}>
                  <SearchRoundedIcon fontSize="small" />
                </InputAdornment>
              }
              inputProps={{
                "aria-label": "search",
              }}
            />
          </FormControl>

          {/* Create Button */}
          {isCreateButtonVisible ? (
            <FormControl
              sx={{
                width: { xs: "100%", md: "25ch" },
                mt: { xs: 2, md: 0 }, // Add margin-top for smaller screens
              }}
              variant="outlined"
            >
              <Button
                variant="contained"
                startIcon={<AddIcon />}
                onClick={() => {
                  setShowCreateUser(true);
                }}
              >
                Crear Objetivo
              </Button>
            </FormControl>
          ) : (
            <></>
          )}
        </Grid>
      </Grid>
      <br />

      {objectives.evaluating.length <= 0 ? <Grid size={{ xs: 12, lg: 12 }}>
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          size={{ xs: 12 }}
        >
          No tienes subordinados en evaluación en este momento.
        </Typography>
      </Grid> : hasPerms(user, "SED.Objetivo.Editar") && hasPerms(user, "SED.Objetivo.Crear") &&    
      <>
      <Grid size={{ xs: 12, lg: 12 }}>
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          size={{ xs: 12 }}
        >
          Evaluando
        </Typography>
      </Grid>
      <Grid container spacing={2} columns={12}>
        
        <Grid size={{ xs: 12, lg: 12 }}>
          <SEDObjectivesTable
            user={user}
            users={fuzzyUserSearch(users, search)}
            objectives={objectives.evaluating}
            setObjectives={setObjectives}
            showCreate={showCreateUser}
            setShowCreate={setShowCreateUser}
            parentReRender={reRender}
            setLoading={setLoading}
          />
        </Grid>
      </Grid>
        <br />
        <br />
      </>
      }
      
      { objectives.evaluated.length <= 0 ?
        <Grid size={{ xs: 12, lg: 12 }}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            size={{ xs: 12 }}
          >
            No estas bajo ninguna evaluación en este momento.
          </Typography>
        </Grid> :
        <>
      <Grid size={{ xs: 12, lg: 12 }}>
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          size={{ xs: 12 }}
        >
          Siendo Evaluado
        </Typography>
      </Grid>
      <Grid container spacing={2} columns={12}>
        <Grid size={{ xs: 12, lg: 12 }}>
          <SEDObjectivesTable
            user={user}
            users={fuzzyUserSearch(users, search)}
            objectives={objectives.evaluated}
            setObjectives={setObjectives}
            showCreate={showCreateUser}
            setShowCreate={setShowCreateUser}
            parentReRender={reRender}
            setLoading={setLoading}
          />
        </Grid>
      </Grid>
          </>}
      
    </>


  );
}
