import * as React from "react";
import { useEffect, useState } from "react";
import Grid from "@mui/material/Grid2";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import hasPerms from "../../../utils/hasPerms";
import SEDCompetenciesTable from "../components/SEDCompetenciesTable";

export default function SedCompetenciesPage({
  user,
  users,
  competencies,
  setCompetencies,
  titles,
  reRender,
  setLoading
}) {
  localStorage.setItem("service", "sed");

  const [search, setSearch] = useState("");
  const [showCreateUser, setShowCreateUser] = useState(false);

  const isCreateButtonVisible = hasPerms(user, "SED.Competencia.CrearPlantilla");

  useEffect(() => {
    setLoading(false);
  }, [user, users, setLoading]);

  return (
    <>
      <Grid container spacing={2} columns={12}>
        <Grid
          container
          alignItems="center"
          justifyContent={"space-between"}
          sx={{ width: "100%" }}
        >
          {/* Search Bar */}
          <FormControl
            sx={{
              width: { xs: "100%", md: "25ch" },
            }}
            variant="outlined"
          >
            <OutlinedInput
              size="small"
              id="search"
              placeholder="Buscar en Objetivos…"
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
              }}
              startAdornment={
                <InputAdornment position="start" sx={{ color: "text.primary" }}>
                  <SearchRoundedIcon fontSize="small" />
                </InputAdornment>
              }
              inputProps={{
                "aria-label": "search",
              }}
            />
          </FormControl>

          {/* Create Button */}
          {isCreateButtonVisible ? (
            <FormControl
              sx={{
                width: { xs: "100%", md: "25ch" },
                mt: { xs: 2, md: 0 }, // Add margin-top for smaller screens
              }}
              variant="outlined"
            >
              <Button
                variant="contained"
                startIcon={<AddIcon />}
                onClick={() => {
                  setShowCreateUser(true);
                }}
              >
                Crear Tarea
              </Button>
            </FormControl>
          ) : (
            <></>
          )}
        </Grid>
      </Grid>
      <br />
      <Grid container spacing={2} columns={12}>
        <Grid size={{ xs: 12, lg: 12 }}>
          <SEDCompetenciesTable
            user={user}
            users={users}
            competencies={competencies}
            setCompetencies={setCompetencies}
            titles={titles}
            setTitles
            showCreate={showCreateUser}
            setShowCreate={setShowCreateUser}
            parentReRender={reRender}
            setLoading={setLoading}
          />
        </Grid>
      </Grid>
    </>
  );
}
