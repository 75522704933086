import * as React from "react";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Drawer, { drawerClasses } from "@mui/material/Drawer";
import Stack from "@mui/material/Stack";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import MenuContent from "../components/MenuContent";
import { useNavigate } from "react-router-dom";
import SelectContent from "../components/SelectContent";
import Box from "@mui/material/Box";

function SideMenuMobile({
  open,
  toggleDrawer,
  allowedPaths,
  menuItems,
  onClick,
  selected,
}) {
  const navigate = useNavigate();

  return (
    <Drawer
      anchor="left"
      open={open}
      onClose={toggleDrawer(false)}
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
        [`& .${drawerClasses.paper}`]: {
          backgroundImage: "none",
          backgroundColor: "background.paper",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          mt: "calc(var(--template-frame-height, 0px) + 4px)",
          p: 1.5,
        }}
      >
        <SelectContent allowedPaths={allowedPaths} />
      </Box>
      <Stack
        sx={{
          maxWidth: "70dvw",
          height: "100%",
        }}
      >

        <Divider />
        <Stack sx={{ flexGrow: 1 }}>
          <MenuContent items={menuItems} onClick={onClick} selected={selected} />
          <Divider />
        </Stack>
        <Stack sx={{ p: 2 }}>
          <Button
            variant="outlined"
            fullWidth
            onClick={() => {
              localStorage.clear();
              navigate("/login");
            }}
            startIcon={<LogoutRoundedIcon />}
          >
            Cerrar Sesion
          </Button>
        </Stack>
      </Stack>
    </Drawer>
  );
}

export default SideMenuMobile;
