import * as React from "react";
import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { alpha } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import AppNavbar from "../../../components/AppNavbar";
import Header from "../../../components/Header";
import SideMenu from "../../../components/SideMenu";
import AppTheme from "../../../theme/AppTheme";
import {
  chartsCustomizations,
  dataGridCustomizations,
  treeViewCustomizations,
} from "../../../theme/customizations";
import api from "../../../utils/api";
import SedObjectivesPage from "./SedObjectivesPage";
import { FlagOutlined, SchoolOutlined, TaskAltOutlined } from "@mui/icons-material";
import SedTasksPage from "./SedTasksPage";
import SedCompetenciesPage from "./SedCompetencyPage";

const xThemeComponents = {
  ...chartsCustomizations,
  ...dataGridCustomizations,
  ...treeViewCustomizations,
};

export default function SedPage() {
  const { subSystem } = useParams();
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [users, setUsers] = useState([]);
  const [objectives, setObjectives] = useState([]);
  const [loading, setLoading] = useState(true);
  const [titles, setTitles] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [competencies, setCompetencies] = useState([]);


  useEffect(() => {
    if (!subSystem) {
      navigate("/SED/Evaluaciones");
    }
  }, [subSystem, navigate]);


  const fetchData = React.useCallback(async () => {
    setLoading(true);
    try {
      const res = await api.get("/user/me", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      });

      const users = await api.get("/user", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      });

      const objectives = await api.get("/objective", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      });

      const tasks = await api.get("/task/preset", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      });

      const competencies = await api.get("/competency/preset", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      });

      const titles = await api.get("/title", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      });

      setTitles(titles.data);
      setTasks(tasks.data);
      setCompetencies(competencies.data);
      setUser(res.data);
      setUsers(users.data);
      setObjectives(objectives.data);
    } catch (e) {
      navigate("/login");
    }
    setLoading(false);
  }, [setUser, setUsers, navigate]);

  useEffect(() => {
    fetchData();
  }, [subSystem, navigate, fetchData]);

  const reRender = fetchData;

  let sidePropsName = ""
  let sidePropsEmail = ""
  let sidePropsAllowedPaths = []
  let sidePropsMenuItems = []
  let sidePropsOnClick = () => { }
  let sidePropsSelected = ""

  if (loading) {
    return (
      <AppTheme themeComponents={xThemeComponents}>
        <CssBaseline enableColorScheme />
        <Box sx={{ display: "flex" }}>
        </Box>
      </AppTheme>
    );
  } else {
    sidePropsName = user.firstNames.split(" ")[0] + " " + user.fatherLastName
    sidePropsEmail = user.email
    sidePropsAllowedPaths = user.permissions.map((x) => x.system)
    sidePropsMenuItems = [
      {
        text: "Evaluaciones",
        to: "Evaluaciones",
        icon: <FlagOutlined />, // icon representing goals or targets
      },
      {
        text: "Tareas",
        to: "Tareas",
        icon: <TaskAltOutlined />, // icon representing tasks or assignments
      },
      {
        text: "Competencias",
        to: "Competencias",
        icon: <SchoolOutlined />, // icon representing learning or skills
      },
    ];

    sidePropsOnClick = (to) => {
      navigate("/SED/" + to);
    }
    sidePropsSelected = subSystem
  }


  return (
    <AppTheme themeComponents={xThemeComponents}>
      <CssBaseline enableColorScheme />
      <Box sx={{ display: "flex" }}>
        <SideMenu
          name={sidePropsName}
          email={sidePropsEmail}
          allowedPaths={sidePropsAllowedPaths}
          menuItems={sidePropsMenuItems}
          onClick={sidePropsOnClick}
          selected={sidePropsSelected}
        />
        <AppNavbar
          name={sidePropsName}
          email={sidePropsEmail}
          allowedPaths={sidePropsAllowedPaths}
          menuItems={sidePropsMenuItems}
          onClick={sidePropsOnClick}
          selected={sidePropsSelected}
        />
        <Box
          component="main"
          sx={(theme) => ({
            flexGrow: 1,
            backgroundColor: theme.vars
              ? `rgba(${theme.vars.palette.background.defaultChannel} / 1)`
              : alpha(theme.palette.background.default, 1),
            overflow: "auto",
          })}
        >
          <Stack
            spacing={2}
            sx={{
              alignItems: "center",
              mx: 3,
              pb: 5,
              mt: { xs: 8, md: 0 },
            }}
          >
            <Header />
            <Box sx={{ width: "100%", maxWidth: { sm: "100%", md: "1700px" } }}>
              {((sys) => {
                switch (sys) {
                  case "Evaluaciones":
                    return (
                      <SedObjectivesPage
                        user={user}
                        users={users}
                        objectives={objectives}
                        setObjectives={setObjectives}
                        reRender={reRender}
                        setLoading={setLoading}
                      />
                    );
                  case "Tareas":
                    return (
                      <SedTasksPage
                        user={user}
                        users={users}
                        tasks={tasks}
                        titles={titles}
                        reRender={reRender}
                        setLoading={setLoading}
                      />
                    )
                  case "Competencias":
                    return <SedCompetenciesPage
                      user={user}
                      users={users}
                      competencies={competencies}
                      titles={titles}
                      reRender={reRender}
                      setLoading={setLoading}
                    />;
                  default:
                    return <></>;
                }
              })(subSystem)}
            </Box>
          </Stack>
        </Box>
      </Box>
    </AppTheme>
  );
}