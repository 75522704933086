import * as React from "react";
import {
  Modal,
  Box,
  Typography,
  TextField,
  Checkbox,
  FormControlLabel,
  Button,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import Autocomplete from "@mui/material/Autocomplete";
import formatRut from "../../../utils/formatRut";
import { FormControl, InputLabel } from "@mui/material";
import act from "../../../utils/autoCompleteTheme";

export default function CreateUserModal({
  openState,
  handleCancel,
  handleSave,
  titles,
  departments,
  users,
}) {
  const [user, setUser] = React.useState({
    rut: "",
    dv: "",
    firstNames: "",
    fatherLastName: "",
    motherLastName: "",
    email: "",
    department: null,
    title: null,
    manager: null,
    isActive: true,
  });

  if (!openState) {
    return null;
  }

  return (
    <Modal
      open={openState}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          border: "0.1px solid #FFFFFF4B",
          borderRadius: 1,
          boxShadow: 24,
          width: { xs: "90%", md: 700 },
          maxHeight: { xs: "90%", md: "80%" },
          p: {
            xs: 2,
            md: 3,
          },
          overflowY: "scroll",
        }}
      >
        <Grid container spacing={2} columns={12}>
          <Grid size={{ xs: 12, lg: 12 }}>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              size={{ xs: 12 }}
            >
              Creando nuevo usuario
            </Typography>
          </Grid>

          <Grid size={{ xs: 12, lg: 12 }}></Grid>
          <Grid size={{ xs: 9, lg: 8 }}>
            <FormControl sx={{ m: 0, width: "100%" }}>
              <InputLabel id="rut-label" sx={{ transform: 'translate(4px, -23px)' }}>RUT</InputLabel>
            <TextField
              id="rut"
              fullWidth
                labelId="rut-label"
                variant="outlined"
              value={user.rut}
              onChange={(e) => {
                user.rut = formatRut(e.target.value, false);
                setUser({ ...user });
              }}
            />
            </FormControl>
          </Grid>
          <Grid size={{ xs: 3, lg: 2 }}>
            <FormControl sx={{ m: 0, width: "100%" }}>
              <InputLabel id="dv-label" sx={{ transform: 'translate(4px, -23px)' }}>DV</InputLabel>
            <TextField
              id="dv"
                labelId="dv-label"
                variant="outlined"
              value={user.dv}
              onChange={(e) => {
                user.dv = e.target.value.replace(/[^0-9kK]/g, "")[1];
                setUser({ ...user });
              }}
            />
            </FormControl>
          </Grid>
          <Grid size={{ xs: 0, lg: 2 }} display={{ xs: "none", lg: "block" }}>
            <FormControlLabel
              label="Activo"
              control={
                <Checkbox
                  checked={user.isActive}
                  onChange={(e) => {
                    user.isActive = !user.isActive;
                    setUser({ ...user });
                  }}
                />
              }
            />
          </Grid>

          <Grid size={{ xs: 12, lg: 12 }}></Grid>
          <Grid size={{ xs: 9, lg: 12 }}>
            <FormControl sx={{ m: 0, width: "100%" }}>
              <InputLabel id="names-label" sx={{ transform: 'translate(4px, -23px)' }}>Nombres</InputLabel>
            <TextField
              id="nombres"
              fullWidth
                labelId="names-label"
                variant="outlined"
              value={user.firstNames}
              onChange={(e) => {
                setUser((prev) => ({
                  ...prev,
                  firstNames: e.target.value,
                }));
              }}
            />
            </FormControl>
          </Grid>
          <Grid size={{ xs: 3, lg: 2 }} display={{ xs: "block", lg: "none" }}>
            <FormControlLabel
              label="Activo"
              control={
                <Checkbox
                  checked={user.isActive}
                  onChange={(e) => {
                    user.isActive = !user.isActive;
                    setUser({ ...user });
                  }}
                />
              }
            />
          </Grid>

          <Grid size={{ xs: 12, lg: 12 }}></Grid>
          <Grid size={{ xs: 6, lg: 6 }}>
            <FormControl sx={{ m: 0, width: "100%" }}>
              <InputLabel id="fathers-last-name-label" sx={{ transform: 'translate(4px, -23px)' }}>Apellido Paterno</InputLabel>
            <TextField
              id="apaterno"
              fullWidth
                labelId="fathers-last-name-label"
                variant="outlined"
              value={user.fatherLastName}
              onChange={(e) => {
                user.fatherLastName = e.target.value;
                setUser({ ...user });
              }}
            />
            </FormControl>
          </Grid>
          <Grid size={{ xs: 6, lg: 6 }}>
            <FormControl sx={{ m: 0, width: "100%" }}>
              <InputLabel id="mothers-last-name-label" sx={{ transform: 'translate(4px, -23px)' }}>Apellido Materno</InputLabel>
            <TextField
              id="amateno"
              fullWidth
                labelId="mothers-last-name-label"
                variant="outlined"
              value={user.motherLastName}
              onChange={(e) => {
                user.motherLastName = e.target.value;
                setUser({ ...user });
              }}
            />
            </FormControl>
          </Grid>

          <Grid size={{ xs: 12, lg: 12 }}></Grid>
          <Grid size={{ xs: 6, lg: 6 }}>
            <FormControl sx={{ m: 0, width: "100%" }}>
              <InputLabel id="mail-label" sx={{ transform: 'translate(4px, -23px)' }}>Mail</InputLabel>
            <TextField
              id="email"
              fullWidth
                labelId="mail-label"
                variant="outlined"
              value={user.email}
              onChange={(e) => {
                user.email = e.target.value;
                setUser({ ...user });
              }}
            />
            </FormControl>
          </Grid>
          {/* LINE 4 */}
          <Grid size={{ xs: 6, lg: 6 }}>
            <FormControl sx={{ m: 0, width: "100%" }}>
              <InputLabel id="dept-label" sx={{ transform: 'translate(4px, -23px)' }}>Departamento</InputLabel>
            <Autocomplete
              size="large"
              disablePortal
              options={departments.map((option) => option.name)}
                sx={act}
              value={user.department?.name ?? ""}
              onChange={(e, value) => {
                user.department = departments.find(
                  (dep) => dep?.name === value
                );
                setUser({ ...user });
              }}
              renderInput={(params) => (
                <TextField {...params} labelId="dept-label" />
              )}
            />
            </FormControl>
          </Grid>

          <Grid size={{ xs: 12, lg: 12 }}></Grid>
          <Grid size={{ xs: 6, lg: 6 }}>
            <FormControl sx={{ m: 0, width: "100%" }}>
              <InputLabel id="title-label" sx={{ transform: 'translate(4px, -23px)' }}>Cargo</InputLabel>
            <Autocomplete
              size="large"
              disablePortal
              options={titles.map((option) => option.name)}
                sx={act}
              value={user.title?.name ?? ""}
              onChange={(e, value) => {
                user.title = titles.find((dep) => dep?.name === value);
                setUser({ ...user });
              }}
                renderInput={(params) => <TextField {...params} labelId="title-label" />}
            />
            </FormControl>
          </Grid>
          {/* LINE 5 */}
          <Grid size={{ xs: 6, lg: 6 }}>
            <FormControl sx={{ m: 0, width: "100%" }}>
              <InputLabel id="manager-label" sx={{ transform: 'translate(4px, -23px)' }}>Supervisor</InputLabel>
            <Autocomplete
              size="large"
              disablePortal
              value={
                user.manager
                  ? `${user.manager?.firstNames?.split(" ")[0]} ${
                      user.manager?.fatherLastName
                    } | ${user.manager.rut}-${user.manager.dv}`
                  : ""
              }
              options={users.map(
                (option) =>
                  `${option.firstNames.split(" ")[0]} ${
                    option.fatherLastName
                  } | ${option.rut}-${option.dv}`
              )}
              onChange={(e, value) => {
                if (!value) {
                  user.manager = null;
                  return;
                }
                let rut = value.split(" | ")[1].split("-")[0];
                user.manager = users.find(
                  (user) => user.rut.toString() === rut.toString()
                );
                setUser({ ...user });
              }}
                sx={act}
              renderInput={(params) => (
                <TextField {...params} labelId="manager-label" />

              )}
            />
            </FormControl>
          </Grid>

          <Grid container justifyContent="flex-end" size={{ xs: 12, lg: 12 }}>
            <Button
              variant="text"
              onClick={() => {
                handleCancel();
              }}
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                handleSave(user, "post");
              }}
            >
              Guardar
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
}
