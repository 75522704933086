let act = {
    width: "100%",
    '& .MuiAutocomplete-endAdornment > *': {
        border: 'none',
        backgroundColor: 'transparent',
    },
    '& .MuiAutocomplete-endAdornment > .MuiAutocomplete-clearIndicator': {
        transform: 'scale(0.85)',
        width: "24px",
        height: "24px",
        backgroundColor: 'transparent',
        borderRadius: '50%',
        transition: 'all 0.3s',
        '&:hover': {
            backgroundColor: '#D63535FF',
            transition: 'all 0.3s',
        }
    },
    '& .MuiAutocomplete-endAdornment > .MuiAutocomplete-popupIndicator': {
        width: "24px",
        height: "24px",
        backgroundColor: 'transparent',
        borderRadius: '50%',
        transition: 'all 0.3s',
        '&:hover': {
            backgroundColor: '#48B0DAFF',
            transition: 'all 0.3s',
        }
    },
    '& .MuiInputAdornment-positionEnd > .MuiIconButton-edgeEnd': {
        width: "24px",
        height: "24px",
        backgroundColor: 'transparent',
        borderRadius: '50%',
        transition: 'all 0.3s',
        border: 'none',
        paddingRight: '15px',
        brightness: '1',
    }
}

export default act;