import { useState, React } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Edit } from "@mui/icons-material";
import hasPerms from "../../../utils/hasPerms";
import api from "../../../utils/api";
import EditCompetencyModal from "./EditCompetencyModal";
import CreateCompetencyModal from "./CreateCompetencyModal";

export default function SEDCompetenciesTable({
  user,
  users,
  competencies,
  titles,
  showCreate,
  setShowCreate,
  parentReRender,
  setLoading
}) {
  const [showEdit, setShowEdit] = useState(false);
  const [selectedCompetency, setSelectedCompetency] = useState(null);

  const handleOpen = (id) => {
    let objCopy = { ...competencies.find((obj) => obj.id === id) };
    setSelectedCompetency(objCopy);
    setShowEdit(true);
  };

  const saveChanges = async (task, type = "post") => {
    setShowCreate(false);

    let res = null;

    try {
      if (type === "post") {
        res = await api.post("/competency/preset", task, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
          },
        });
      } else if (type === "put") {
        res = await api.put("/competency/preset/" + task.id, task, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
          },
        });
      } else {
        throw new Error("Invalid type for saveChanges function");
      }
      if (!res) {
        throw new Error("No response from server");
      }

      setShowCreate(false);
      setShowEdit(false);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
    parentReRender();
  };

  let columns = [
    {
      field: "ID",
      headerName: "ID",
      sortable: true,
      width: 30,
      valueGetter: (value, row) => `${row.id}`,
    },
    {
      field: "title",
      headerName: "Nombre del Objetivo",
      width: 225,
      valueGetter: (value, row) => `${row.title}`,
    },
    {
      field: "description",
      headerName: "Descripcion",
      width: 100,
      valueGetter: (value, row) => {
        return `${row.description}`;
      },
    },
    {
      field: "titleCount",
      headerName: "Titulos Asignados",
      width: 100,
      valueGetter: (value, row) => `${row.titles.length}`,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => {
        return [
          hasPerms(user, "SED.Tarea.ActualizarPlantilla") && (
            <Edit onClick={() => handleOpen(id)} />
          ),
        ].filter(Boolean); // Filter out undefined values
      },
    },
  ];

  return (
    <>
      <DataGrid
        rows={competencies}
        columns={columns}
        getRowId={(row) => row.id}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize:
                users.length === 0
                  ? 20
                  : users.length <= 20
                    ? users.length
                    : 20,
            },
          },
        }}
        disableColumnResize
        pageSizeOptions={[users.length, 10, 20, 50]}
        density="compact"
      />
      
      <CreateCompetencyModal
        titles={titles}
        openState={showCreate}
        handleCancel={() => setShowCreate(false)}
        handleSave={saveChanges}
      />
      <EditCompetencyModal
        competency={selectedCompetency}
        titles={titles}
        openState={showEdit}
        handleCancel={() => setShowEdit(false)}
        handleSave={saveChanges}
      />
    </>
  );
}