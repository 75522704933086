import { useState, React } from "react";
import { Edit, Fingerprint } from "@mui/icons-material";
import { DataGrid } from "@mui/x-data-grid";
import EditUserModal from "./EditUserModal";
import EditPermisionsModal from "./EditPermisionsModal";
import hasPerms from "../../../utils/hasPerms";
import CreateUserModal from "./CreateUserModal";
import api from "../../../utils/api";

export default function SDOUsersTable({
  users,
  setUsers,
  titles,
  departments,
  perms,
  showCreate,
  setShowCreate,
  parentReRender,
  setLoading,
}) {
  const [showEdit, setShowEdit] = useState(false);
  const [showPerms, setShowPerms] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  const handleOpen = (id) => {
    let userCopy = { ...users.find((user) => user.rut === id) };
    setSelectedUser(userCopy);
    setShowEdit(true);
  };

  const handleshowPerms = (id) => {
    let userCopy = { ...users.find((user) => user.rut === id) };
    setSelectedUser(userCopy);
    setShowPerms(true);
  };

  const saveUser = async (user, type = "post") => {
    setShowCreate(false);

    try {
      let userUpdateData = {};

      userUpdateData.firstNames = user.firstNames;
      userUpdateData.fatherLastName = user.fatherLastName;
      userUpdateData.motherLastName = user.motherLastName;
      userUpdateData.email = user.email;
      userUpdateData.manager = user.manager?.rut ?? null;
      userUpdateData.department = user.department?.id ?? null;
      userUpdateData.title = user.title?.persistentId ?? null;

      let res = null;

      if (type === "put") {
        res = await api.put("/user/" + user.rut, userUpdateData, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
          },
        });
      } else if (type === "post") {
        userUpdateData.rut = user.rut.replaceAll(".", "").replaceAll("-", "");
        userUpdateData.dv = user.dv ?? 0;

        res = await api.post("/user/", userUpdateData, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
          },
        });
      }

      if (!res) {
        throw new Error("No response from server");
      }

      let _users = [...users].filter((u) => u.rut !== user.rut);
      _users.push(res.data);

      setUsers(_users);
      setSelectedUser(null);
      setShowEdit(false);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
    parentReRender();
  };

  const buildPerms = (system) => ({
    ...system,
    modules: Object.fromEntries(
      Object.entries(system.modules).map(([moduleKey, perms]) => [
        moduleKey,
        perms
          .filter((permission) => permission.status === true) // Keep only permissions with status === true
          .map(({ status, ...rest }) => rest), // Remove the status field
      ])
    ),
  });

  const saveUserPermisions = async (user, permisions) => {
    setShowPerms(false);
    setLoading(true);
    const savePerms = permisions
      .map((system) => buildPerms(system))
      .filter(
        (system) =>
          Object.values(system.modules).some(
            (modulePerms) => modulePerms.length > 0
          ) // Remove systems with no remaining permissions
      );

    try {
      await api.put("/permissions/" + user.rut, savePerms, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      });

      setSelectedUser(null);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
    parentReRender();
  };

  let currentUser = users.filter(
    (user) => user.rut.toString() === localStorage.getItem("userRut").toString()
  )[0];
  let columns = [
    {
      field: "rut",
      headerName: "RUT",
      sortable: false,
      width: 95,
      valueGetter: (value, row) => `${row.rut}-${row.dv}`,
    },
    {
      field: "name",
      headerName: "Nombre Completo",
      width: 225,
      valueGetter: (value, row) =>
        `${row.firstNames} ${row.fatherLastName} ${row.motherLastName}`,
    },
    {
      field: "title",
      headerName: "Cargo",
      width: 215,
      valueGetter: (value, row) => row.title?.name ?? "",
    },
    {
      field: "supervisor",
      headerName: "Supervisor",
      width: 250,
      valueGetter: (value, row) => {
        let manager = users.find((user) => user.rut === row?.manager?.rut);

        if (!manager) {
          return "Sin Supervisor";
        }

        return `${manager.firstNames} ${manager.fatherLastName} ${manager.motherLastName}`;
      },
    },
    {
      field: "email",
      headerName: "eMail",
      width: 225,
    },
    {
      field: "isActive",
      headerName: "Status",
      width: 90,
      valueGetter: (value) => (value ? "Activo" : "Inactivo"),
    },
    {
      field: "actions",
      type: "actions",
      headerName: "",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => {
        return [
          hasPerms(currentUser, "SDO.Usuario.Editar") && (
            <Edit onClick={() => handleOpen(id)} />
          ),
          hasPerms(currentUser, "SDO.Permisos.SetearPorRut") && (
            <Fingerprint onClick={() => handleshowPerms(id)} />
          ),
        ].filter(Boolean); // Filter out undefined values
      },
    },
  ];

  return (
    <>
      <DataGrid
        rows={users}
        columns={columns}
        getRowId={(row) => row.rut}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize:
                users.length === 0
                  ? 20
                  : users.length <= 20
                  ? users.length
                  : 20,
            },
          },
        }}
        disableColumnResize
        pageSizeOptions={[users.length, 10, 20, 50]}
        density="compact"
      />
      <CreateUserModal
        openState={showCreate}
        handleCancel={() => setShowCreate(false)}
        handleSave={saveUser}
        titles={titles}
        departments={departments}
        users={users}
      />
      <EditUserModal
        user={selectedUser}
        openState={showEdit}
        handleCancel={() => setShowEdit(false)}
        handleSave={saveUser}
        titles={titles}
        departments={departments}
        users={users}
      />
      <EditPermisionsModal
        user={selectedUser}
        handleCancel={() => setShowPerms(false)}
        allperms={perms}
        openState={showPerms}
        handleSave={saveUserPermisions}
      />
    </>
  );
}
