import * as React from "react";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import Select, { selectClasses } from "@mui/material/Select";
import { useNavigate, useLocation } from "react-router-dom";


export default function SelectContent({ allowedPaths = [] }) {
  const location = useLocation();

  const [system, setSystem] = React.useState(
    ("sauce" + location.pathname).split("/")[1]
  );
  const navigate = useNavigate();

  const handleChange = (event) => {
    setSystem(event.target.value);

    navigate(`/${event.target.value}`);
  };

  return (
    <Select
      labelId="company-select"
      id="company-simple-select"
      value={system}
      onChange={handleChange}
      displayEmpty
      inputProps={{ "aria-label": "Select System" }}
      fullWidth
      sx={{
        maxHeight: 56,
        width: 215,
        "&.MuiList-root": {
          p: "8px",
        },
        [`& .${selectClasses.select}`]: {
          display: "flex",
          alignItems: "center",
          gap: "2px",
          pl: 1,
        },
      }}
    >
      {([...allowedPaths].includes("SDO") ||
        [...allowedPaths].includes("*")) && (
        <MenuItem value="SDO">
          <ListItemText primary="SDO" secondary="Desarrollo Organizacional" />
        </MenuItem>
      )}
      {([...allowedPaths].includes("SED") ||
        [...allowedPaths].includes("*")) && (
        <MenuItem value="SED">
          <ListItemText primary="SED" secondary="Evaluación de Desenpeño" />
        </MenuItem>
      )}
    </Select>
  );
}
