import React from "react";
import { Box, Button, Typography } from "@mui/material";
import AppTheme from "../theme/AppTheme";
import CssBaseline from "@mui/material/CssBaseline";
import Stack from "@mui/material/Stack";
import { useNavigate } from "react-router-dom";
import {
    chartsCustomizations,
    dataGridCustomizations,
    datePickersCustomizations,
    treeViewCustomizations,
} from "../theme/customizations";

const xThemeComponents = {
    ...chartsCustomizations,
    ...dataGridCustomizations,
    ...datePickersCustomizations,
    ...treeViewCustomizations,
};

export default function NoAuthPage(props) {
    const navigate = useNavigate();

    return (
        <AppTheme {...props} themeComponents={xThemeComponents}>
            <CssBaseline enableColorScheme />
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100vh",
                    backgroundColor: "background.default",
                    padding: 4,
                }}
            >
                <Box
                    sx={{
                        textAlign: "center",
                        maxWidth: 600,
                        width: "100%",
                        p: 4,
                        borderRadius: 4,
                        boxShadow: 3,
                        backgroundColor: "background.paper",
                    }}
                >
                    <Stack spacing={3}>
                        <Typography variant="h3" fontWeight={600}>
                            Acceso no autorizado
                        </Typography>

                        <Typography variant="body1" color="text.secondary">
                            No tienes permiso para acceder con tu cuenta de Google.
                            <br />
                            Por favor, contacta a tu supervisor o escribe a{" "}
                            <strong>ayudapp@elsauce.cl</strong> para obtener acceso a la plataforma.
                        </Typography>

                        <Button
                            onClick={() => navigate("/login")}
                            variant="contained"
                            size="large"
                        >
                            Volver al inicio
                        </Button>
                    </Stack>
                </Box>
            </Box>
        </AppTheme>
    );
}
