import React from "react";
import packageJson from "../../package.json";
import "./VersionBadge.css";

const VersionBadge = () => {
  return (
    <div
      className="rainbow-badge"
      onClick={() => {
        console.log("vBadge clicked");
        console.log(`v${packageJson.version}`);
        navigator.clipboard.writeText(`v${packageJson.version}`);
        console.log("This code was also copied to the clipboard");
      }}
    >
      <span>{`v${packageJson.version}`}</span>
    </div>
  );
};

export default VersionBadge;
