import * as React from "react";
import { styled } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import MuiToolbar from "@mui/material/Toolbar";
import { tabsClasses } from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import DashboardRoundedIcon from "@mui/icons-material/DashboardRounded";
import SideMenuMobile from "./SideMenuMobile";
import { useLocation } from "react-router-dom";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import MenuButton from "./MenuButton";


const Toolbar = styled(MuiToolbar)({
  width: "100%",
  padding: "12px",
  display: "flex",
  flexDirection: "column",
  alignItems: "start",
  justifyContent: "center",
  gap: "12px",
  flexShrink: 0,
  [`& ${tabsClasses.flexContainer}`]: {
    gap: "8px",
    p: "8px",
    pb: 0,
  },
});

export default function AppNavbar({
  name,
  email,
  allowedPaths,
  menuItems,
  onClick,
  selected,
}) {
  const [open, setOpen] = React.useState(false);
  const location = useLocation();

  const toggleDrawer = () => () => {
    setOpen(!open);
  };

  return (
    <AppBar
      position="fixed"
      sx={{
        display: { xs: "auto", md: "none" },
        boxShadow: 0,
        bgcolor: "background.paper",
        backgroundImage: "none",
        borderBottom: "1px solid",
        borderColor: "divider",
        top: "var(--template-frame-height, 0px)",
      }}
    >
      <Toolbar variant="regular" >
        <Stack
          direction="row"
          sx={{
            alignItems: "center",
            flexGrow: 1,
            width: "100%",
            gap: 1,
          }}
          onClick={toggleDrawer()}
        >

          <MenuButton aria-label="menu" onClick={toggleDrawer(true)}>
            <MenuRoundedIcon />
          </MenuButton>
          <img src="/favicon.png" alt="Logo" width="40" height="40" />
          <Stack
            direction="row"
            spacing={0}
            sx={{ justifyContent: "center", alignContent: "center", alignItems: "center", mr: "auto" }}
          >
            <Typography
              variant="h7"
              component="h7"
              sx={{
                color: "text.primary",
                opacity: 0.7,
              }}
            >
              {("Sauce" + location.pathname).split("/").slice(0, ("Sauce" + location.pathname).split("/").length - 1).filter((e) => e !== "").join(" / ") + " / "}
            </Typography>
            <Typography
              paddingLeft="4px"
              variant="h4"
              component="h1"
              sx={{ color: "text.primary" }}
            >
              {selected}
            </Typography>
          </Stack>

          <SideMenuMobile
            menuItems={menuItems ?? []}
            onClick={onClick ?? (() => { })}
            allowedPaths={allowedPaths ?? []}
            selected={selected ?? ""}
            open={open}
            toggleDrawer={toggleDrawer}
          />
        </Stack>
      </Toolbar>
    </AppBar>
  );
}

export function CustomIcon() {
  return (
    <Box
      sx={{
        width: "1.5rem",
        height: "1.5rem",
        bgcolor: "black",
        borderRadius: "999px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        alignSelf: "center",
        backgroundImage:
          "linear-gradient(135deg, hsl(210, 98%, 60%) 0%, hsl(210, 100%, 35%) 100%)",
        color: "hsla(210, 100%, 95%, 0.9)",
        border: "1px solid",
        borderColor: "hsl(210, 100%, 55%)",
        boxShadow: "inset 0 2px 5px rgba(255, 255, 255, 0.3)",
      }}
    >
      <DashboardRoundedIcon color="inherit" sx={{ fontSize: "1rem" }} />
    </Box>
  );
}
