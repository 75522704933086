import { useEffect } from 'react';
import api from './api';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Typography } from '@mui/material';

const GoogleLogin = () => {
    const navigate = useNavigate();

    useEffect(() => {
        window.google.accounts.oauth2.initTokenClient({
            client_id: '76056910358-cfg8jc1mc7kscf2c1jevkl5439nrohdc.apps.googleusercontent.com',
            scope: 'profile email https://www.googleapis.com/auth/user.organization.read',
            callback: async (response) => {
                let res = await api.post('/auth/google', {
                    token: response.access_token,
                })

                if (res.data.accessToken) {
                    localStorage.setItem('accessToken', res.data.accessToken);
                    navigate('/SDO');
                } else {
                    navigate('/noauth');
                }

            },
        });
    }, [navigate]);

    const handleLogin = () => {
        const tokenClient = window.google.accounts.oauth2.initTokenClient({
            client_id: '76056910358-cfg8jc1mc7kscf2c1jevkl5439nrohdc.apps.googleusercontent.com',
            scope: 'profile email https://www.googleapis.com/auth/user.organization.read',
            callback: async (response) => {
                let res = await api.post('/auth/google', {
                    token: response.access_token,
                })

                if (res.data.accessToken) {
                    localStorage.setItem('accessToken', res.data.accessToken);
                    navigate('/SDO');
                } else {
                    navigate('/noauth');
                }

            },
        });

        tokenClient.requestAccessToken();
    };
    return <Button
        onClick={handleLogin}
        variant="outlined"
        sx={{
            textTransform: 'none',
            color: '#000',
            borderColor: '#ddd',
            backgroundColor: '#fff',
            '&:hover': {
                backgroundColor: '#f7f7f7',
                borderColor: '#ccc',
            },
            padding: '6px 12px',
        }}
        fullWidth
    >
        <Box
            component="img"
            src="https://developers.google.com/identity/images/g-logo.png"
            alt="Google logo"
            sx={{ width: 20, height: 20, mr: 1 }}
        />
        <Typography variant="body1" fontWeight={500}>
            Iniciar sesión con Google
        </Typography>
    </Button>
};

export default GoogleLogin;
