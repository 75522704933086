import { Edit, Check } from "@mui/icons-material";
import { DataGrid } from "@mui/x-data-grid";
import { useState, React } from "react";
import hasPerms from "../../../utils/hasPerms";
import CreateTitleModal from "./CreateTitleModal";
import EditTitleModal from "./EditTitleModal";
import api from "../../../utils/api";

export default function SDOTitlesTable({
  user,
  users,
  titles,
  setTitles,
  departments,
  showCreate,
  setShowCreate,
  parentReRender,
  setLoading,
}) {
  const [showEdit, setShowEdit] = useState(false);
  const [selectedTitle, setSelectedTitle] = useState(null);

  const handleOpen = (row) => {
    let titleCopy = { ...titles.find((title) => title.id === row.id) };
    setSelectedTitle(titleCopy);
    setShowEdit(true);
  };

  const saveTitle = async (title, type = "post") => {
    setShowCreate(false);
    setShowEdit(false);
    setLoading(true);
    try {
      let res = null;
      if (type === "put") {
        res = await api.put("/title/" + title.persistentId, title, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
          },
        });
      } else if (type === "post") {
        res = await api.post("/title/", title, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
          },
        });
      }
      if (!res) throw new Error("No response from server");

      let _titles = [...titles].filter((t) => t.id !== title.id);
      _titles.push(res.data);

      setTitles([..._titles]);
      setSelectedTitle(null);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
    parentReRender();
  };

  const handleValidate = async (row) => {
    await api.put(
      `/title/${row.id}/validate`,
      {},
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      }
    );
    parentReRender();
  };

  let columns = [
    {
      field: "id",
      headerName: "ID",
      sortable: true,
      width: 75,
      valueGetter: (value, row) => `${row.persistentId}`,
    },
    {
      field: "version",
      headerName: "V",
      width: 50,
      valueGetter: (value, row) => row.version,
    },
    {
      field: "name",
      headerName: "Cargo",
      width: 225,
      valueGetter: (value, row) => `${row.name}`,
    },
    {
      field: "department",
      headerName: "Departamento",
      width: 150,
      valueGetter: (value, row) => `${row.department?.name}`,
    },
    {
      field: "workers",
      headerName: "Trabajadores",
      width: 150,
      valueGetter: (value, row) => `${row.users?.length}`,
    },
    {
      field: "authority",
      headerName: "Autoridad",
      width: 350,
      valueGetter: (value, row) => row.authorityDescription,
    },
    {
      field: "manager",
      headerName: "Gerente",
      width: 350,
      valueGetter: (value, row) =>
        `${row.manager?.firstNames ?? ""} ${
          row.manager?.fatherLastName ?? ""
        } ${row.manager?.motherLastName ?? ""}`,
    },
    {
      field: "status",
      headerName: "Status",
      width: 90,
      valueGetter: (value) =>
        value === -1 ? "Deprecado" : value === 0 ? "Inactivo" : "Activo",
    },
    {
      field: "edit-action",
      type: "actions",
      headerName: "",
      width: 50,
      cellClassName: "actions",
      getActions: (id) => {
        return [
          hasPerms(user, "SDO.Cargo.Editar") && (
            <Edit onClick={() => handleOpen(id)} />
          ),
        ].filter(Boolean); // Filter out undefined values
      },
    },
    {
      field: "validate-action",
      type: "actions",
      headerName: "",
      width: 50,
      cellClassName: "actions",
      getActions: (id) => {
        return [
          !id.row.isValidated &&
            hasPerms(user, "SDO.Cargo.Validar") &&
            id.row.validator?.rut.toString() === user.rut.toString() && (
              <Check onClick={() => handleValidate(id)} />
            ),
        ].filter(Boolean); // Filter out undefined values
      },
    },
  ];

  return (
    <>
      <DataGrid
        rows={titles}
        columns={columns}
        getRowId={(row) => row.id}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize:
                titles.length === 0
                  ? 20
                  : titles.length <= 20
                  ? titles.length
                  : 20,
            },
          },
        }}
        disableColumnResize
        pageSizeOptions={[titles.length, 10, 20, 50]}
        density="compact"
      />
      <EditTitleModal
        title={selectedTitle}
        setTitle={setSelectedTitle}
        openState={showEdit}
        handleCancel={() => setShowEdit(false)}
        handleSave={saveTitle}
        titles={titles}
        departments={departments}
        users={users}
      />
      <CreateTitleModal
        openState={showCreate}
        handleCancel={() => setShowCreate(false)}
        handleSave={saveTitle}
        titles={titles}
        departments={departments}
        users={users}
      />
    </>
  );
}
