import * as React from "react";
import { useEffect, useState } from "react";
import Grid from "@mui/material/Grid2";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import hasPerms from "../../../utils/hasPerms";
import SDODepartmentsTable from "../components/SDODepartmentsTable";

export default function SdoDepartmentsPage({
  user,
  users,
  setUsers,
  titles,
  setTitles,
  departments,
  setDepartments,
  perms,
  setPerms,
  reRender,
  setLoading,
}) {
  localStorage.setItem("service", "intranet");

  const [search, setSearch] = useState("");
  const [showCreateDepartment, setShowCreateDepartment] = useState(false);

  const isCreateButtonVisible = hasPerms(user, "SDO.Departamentos.Crear");

  useEffect(() => {
    setLoading(false);
  }, [user, users, titles, departments, setLoading]);

  let fuzzySearch = (departments, searchQuery) => {
    if (searchQuery === "") return departments;
    let scored = [];
    let tokens = searchQuery.split(" ");

    departments.forEach((department) => {
      let score = 0;

      tokens.forEach((token) => {
        if (department.name.toUpperCase().includes(token.toUpperCase()))
          score += 4;
        if (department.description.toUpperCase().includes(token.toUpperCase()))
          score += 2;
      });

      department["score"] = score;
      scored.push(department);
    });

    return scored.sort((a, b) => {
      return b.score - a.score;
    });
  };

  return (
    <>
      <Grid container spacing={2} columns={12}>
        <Grid
          container
          alignItems="center"
          justifyContent={
            isCreateButtonVisible ? "space-between" : "flex-start"
          }
          sx={{ width: "100%" }}
        >
          {/* Search Bar */}
          <FormControl
            sx={{
              width: isCreateButtonVisible
                ? { xs: "100%", md: "25ch" }
                : "100%",
            }}
            variant="outlined"
          >
            <OutlinedInput
              size="small"
              id="search"
              placeholder="Buscar en Cargos"
              onKeyUp={(e) => {
                setSearch(e.target.value);
              }}
              startAdornment={
                <InputAdornment position="start" sx={{ color: "text.primary" }}>
                  <SearchRoundedIcon fontSize="small" />
                </InputAdornment>
              }
              inputProps={{
                "aria-label": "search",
              }}
            />
          </FormControl>

          {/* Create Button */}
          {isCreateButtonVisible && (
            <FormControl
              sx={{
                width: { xs: "100%", md: "25ch" },
                mt: { xs: 2, md: 0 }, // Add margin-top for smaller screens
              }}
              variant="outlined"
            >
              <Button
                variant="contained"
                startIcon={<AddIcon />}
                onClick={() => {
                  setShowCreateDepartment(true);
                }}
              >
                Crear Departamento
              </Button>
            </FormControl>
          )}
        </Grid>
      </Grid>
      <br />
      <Grid container spacing={2} columns={12}>
        <Grid size={{ xs: 12, lg: 12 }}>
          <SDODepartmentsTable
            user={user}
            users={users}
            setUsers={setUsers}
            titles={fuzzySearch(departments, search)}
            setTitles={setTitles}
            departments={departments}
            setDepartments={setDepartments}
            perms={perms}
            setPerms={setPerms}
            showCreate={showCreateDepartment}
            setShowCreate={setShowCreateDepartment}
            parentReRender={reRender}
            setLoading={setLoading}
          />
        </Grid>
      </Grid>
    </>
  );
}
