import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import MuiDrawer, { drawerClasses } from "@mui/material/Drawer";
import {
  TextField,
  Button,
  Stack,
  Typography,
  CircularProgress,
} from "@mui/material";
import { useColorScheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import formatRut from "../utils/formatRut";
import api from "../utils/api";

const Drawer = styled(MuiDrawer)({
  width: { md: "550px" },
  maxWidth: 400,
  flexShrink: 0,
  boxSizing: "border-box",
  mt: 10,
  [`& .${drawerClasses.paper}`]: {
    width: "100%",
    boxSizing: "border-box",
    maxWidth: 400,
  },
});

export default function SidePWReset() {
  useEffect(() => {
    alert("El sistema requiere que se cambie la contraseña");
  }, []);

  const navigate = useNavigate();

  const [user, setUser] = useState("");
  const [oldPw, setOldPw] = useState("");
  const [newPw, setNewPw] = useState("");
  const [confirmNewPw, setConfirmNewPw] = useState("");

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const { mode } = useColorScheme();

  const handleRequest = async (rut) => {
    setLoading(true);

    if (newPw !== confirmNewPw) {
      setError("Las contraseñas no coinciden");
      setLoading(false);
      return;
    }

    try {
      await api.post("/auth/change-password", {
        rut: user.replaceAll(".", "").split("-")[0],
        oldPassword: oldPw,
        newPassword: newPw,
      });

      navigate("/login");
    } catch (error) {
      console.error(error);
      setError("Error al resetear la contraseña");
    }
    setLoading(false);
  };

  return (
    <Drawer
      variant="permanent"
      sx={{
        display: "block",
        [`& .${drawerClasses.paper}`]: {
          backgroundColor: "background.paper",
        },
      }}
    >
      <Stack
        spacing={2}
        height={"100%"}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "center",
          mt: "calc(var(--template-frame-height, 0px) + 4px)",
          p: 1,
        }}
      >
        <img
          src={
            mode === "light"
              ? "https://www.constructoraelsauce.cl/resources/media/elsauce/old/logo_transp_blue.png"
              : "https://www.constructoraelsauce.cl/resources/media/elsauce/old/logo_transp_white.png"
          }
          style={{ paddingTop: "25px" }}
          width="70%"
          alt="El Sauce Logo"
        />

        <Stack spacing={3} style={{ width: "90%" }}>
          <TextField
            id="user-input"
            label="Rut"
            variant="standard"
            value={user}
            onChange={(e) => {
              setUser(formatRut(e.target.value, true));
            }}
          />

          <TextField
            id="old-pw-input"
            label="Contraseña Antigua o Temporal"
            variant="standard"
            type="password"
            value={oldPw}
            onChange={(e) => {
              setOldPw(e.target.value, true);
            }}
          />

          <TextField
            id="new-pw-input"
            label="Nueva Contraseña"
            variant="standard"
            type="password"
            value={newPw}
            onChange={(e) => {
              setNewPw(e.target.value, true);
            }}
          />

          <TextField
            id="confirm-new-pw-input"
            label="Repite Nueva Contraseña"
            variant="standard"
            type="password"
            value={confirmNewPw}
            onChange={(e) => {
              setConfirmNewPw(e.target.value, true);
            }}
          />

          <Button
            variant="contained"
            onClick={handleRequest}
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} /> : "Cambiar Contraseña"}
          </Button>
          {error && (
            <Typography color="error" variant="body2" sx={{ mt: 1 }}>
              {error}
            </Typography>
          )}
        </Stack>

        <p> © 2024. Constructora El Sauce S.A.</p>
      </Stack>
    </Drawer>
  );
}
